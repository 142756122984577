import { Button, Col, Form, Input, Row, Select, Space, Table, message, notification } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnOloProduct } from "components/Columns/ColumnOloProduct";
import useCurrentMenu from "customhook/useCurrentMenu";
import { OloProductReponseEntity } from "Entity/OloProductEntity";
import { fetchAPI, MainRowsResponse } from "helpers/apiHelpers";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import ProductForm from "./ProductForm";
import queryString from "query-string";
import BreadcrumbComponent from "components/BreadcrumbComponent";
import { CSVLink } from "react-csv";
import moment from "moment";

const { Option } = Select;

const DonwloadHeadersCSV = [
  { label: "Code", key: "code" },
  { label: "Type", key: "type" },
  { label: "Name", key: "name" },
  { label: "Description", key: "description" },
  { label: "Parent", key: "parent" },
  { label: "Status", key: "active" },
  { label: "Created At", key: "created_at" },
  { label: "Image", key: "image_external_url" },
  { label: "Metadata", key: "metadata_csv" },
];

type EntitySourceType = {
  flag: 'ADD' | 'EDIT' | 'DELETE';
  dataSource: OloProductReponseEntity;
}

type PaginationType = {
  page: number,
  per_page: number,
  total: number
}

const InitialEntitySource:EntitySourceType = {
  flag: 'ADD',
  dataSource: {},
}

const Product = () => {
  const currentMenu = useCurrentMenu();
  const [form] = Form.useForm();
  const [notif, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMsg] = message.useMessage();
  const [IsModalOpen, SetIsModalOpen] = useState(false)
  const [DataSource, SetDataSource] = useState<{data: OloProductReponseEntity[], pagination:PaginationType}>({data: [], pagination: {page:1, per_page: 10, total: 0}})
  const [DataSourceDownload, SetDataSourceDownload] = useState<OloProductReponseEntity[]>([])
  const [EntitySource, SetEntitySource] = useState<EntitySourceType>(InitialEntitySource)

  const fetchDatas = useCallback(async (query?: {size?: number, page?:number}) => {
    try {
      const params:any = {
        ...query,
        page: query?.page ?? DataSource.pagination.page,
        size: query?.size ?? DataSource.pagination.per_page,
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<OloProductReponseEntity> | null = await fetchAPI(
        `olo/products?${queryString.stringify(params)}`
      );
      const datas = response?.data?.rows?.map((data:OloProductReponseEntity) => {

        return {
          ...data,
          created_at: data.created_at ? moment(data.created_at).zone(data.created_at).format("DD MMM YYYY HH:mm") : '',
          updated_at: data.updated_at ? moment(data.updated_at).zone(data.updated_at).format("DD MMM YYYY HH:mm") : ''
        };
      }) ?? [];
      SetDataSource({
        ...DataSource,
        data: datas,
        pagination: {
          page: query?.page ?? DataSource.pagination.page,
          per_page: query?.size ?? DataSource.pagination.per_page,
          total: response?.data.count as number,
        }
      })
    } catch (error: any) {
      notif.error({
        message: 'Error',
        description: `${error.message}`
      })
    }
  }, [notif, DataSource]);
  
  const APIDownloadProduct = useCallback(async (query?: {code?: string, name?:string}) => {
    try {
      messageApi.loading({
        content: 'Download in progress..',
        duration: 0
      })

      const params:any = {
        ...query,
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<OloProductReponseEntity> | null = await fetchAPI(
        `olo/products?${queryString.stringify(params)}`
      );
      const datas = response?.data?.rows?.map((data:OloProductReponseEntity) => {

        const metadata = (data?.metadata as any)?.map((item:any) => {
            return "{key:" + item.key + ", value:" + item.value + "}"
          }) ?? []

        return {
          ...data,
          metadata_csv: metadata,
          active: data.active ? "Active" : "Not Active",
          created_at: data.created_at ? moment(data.created_at).zone(data.created_at).format("DD MMM YYYY HH:mm") : '',
          updated_at: data.updated_at ? moment(data.updated_at).zone(data.updated_at).format("DD MMM YYYY HH:mm") : ''
        };
      }) ?? [];
      SetDataSourceDownload(datas)

      setTimeout(() => {
        csvLinkRef?.current?.link.click();
        messageApi.destroy()
      }, 1500);

    } catch (error: any) {
      notif.error({
        message: 'Error',
        description: `${error.message}`
      })
      messageApi.destroy()
    }
  }, [messageApi, notif]);

  // const convertToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  //   return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result);
  //       reader.onerror = (error) => reject(error);
  //   });
  // };

  const APIAddProduct = useCallback(async (val:any) => {
    // const base64Image = val.image?.file? await convertToBase64(val.image?.file) : '';
    const data = {
      code: val.code,
      type: val.type,
      name: val.name,
      description: val.description,
      parent: val.parent,
      title: val.title,
      active: val.active ?? false,
      // image: val?.image?.file ? base64Image as string : '',
      metadata: val.metadata,
      groupCode: val.group_code,
      categoryCode: val.category_code,
      basePrice: val.base_price,
      gimmickPrice: val.gimmick_price,
      imageExternalUrl: val.image_external_url,
    }

    messageApi.loading({
      content: 'Action in progress..',
      duration: 0
    })

    await fetchAPI(`olo/products`, 'POST', data).then((res:any) => {
        if(res.code === 200 ) {
            notif.success({
                message: 'Success',
                description: "Data Successfully Created"
            })
            messageApi.destroy()
            fetchDatas()
            SetIsModalOpen(false)
        }
    }).catch((error: any) => {
        notif.error({
            message: 'Error',
            description: `${error.message}`
        })
        messageApi.destroy()
    });

  }, [notif, messageApi, fetchDatas])

  const APIPutProduct = useCallback(async (val:any) => {
    // const base64Image = val.image?.file? await convertToBase64(val.image?.file) : '';
    const data = {
      type: val.type,
      name: val.name,
      description: val.description,
      parent: val.parent,
      title: val.title,
      active: val.active,
      // image: val?.image?.file ? base64Image as string : '',
      metadata: val.metadata,
      groupCode: val.group_code,
      categoryCode: val.category_code,
      basePrice: val.base_price,
      gimmickPrice: val.gimmick_price,
      imageExternalUrl: val.image_external_url,
    }

    messageApi.loading({
        content: 'Action in progress..',
        duration: 0
    })

    await fetchAPI(`olo/products/${val.code}`, 'PUT', data).then((res:any) => {
      if(res.code === 200 ) {
          notif.success({
              message: 'Success',
              description: res.data
          })
          messageApi.destroy()
          fetchDatas()
          SetIsModalOpen(false)
      }
    }).catch((error: any) => {
        notif.error({
            message: 'Error',
            description: `${error.message}`
        })
        messageApi.destroy()
    });
  }, [fetchDatas, SetIsModalOpen, notif, messageApi])

  const ApiDeleteContent = async (item:OloProductReponseEntity) => {
    if(window.confirm('Are you sure?')) {
        messageApi.loading({
            content: 'Action in progress..',
            duration: 0
        })

        await fetchAPI(`olo/products/${item.code}`, 'DELETE').then((res:any) => {
            if(res.code === 200 ) {
                notif.success({
                    message: 'Success',
                    description: res.data
                })
              fetchDatas()
              SetIsModalOpen(false)
            }
            messageApi.destroy()
        }).catch((error: any) => {
            notif.error({
                message: 'Error',
                description: `${error.message}`
            })
            messageApi.destroy()
        });
    }
  }

  const handleFilter =  (val?: {type?: string; searchBy?: any; searchData?: string}) => {
    const query = {
      page: 1,
      size: 10,
    }
    if(val?.type) {
      Object.assign(query, {type: val.type})
    }
    
    if(val?.searchBy && val?.searchData) {
      Object.assign(query, {[val?.searchBy]: [val?.searchData]})
    }

    fetchDatas(query);
  }

  const onChangePage = async (page:number, per_page:number) => {
    await fetchDatas({
        page,
        size: per_page
    })
  }

  const handleClearFilter = () => {
    form.resetFields()
    fetchDatas()
  }

  const handleDownloadData = async (val?: {searchBy?: any; searchData?: string}) => {
    await APIDownloadProduct({
      [val?.searchBy]: [val?.searchData]
    })
  }

  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  useEffect(() => {
    fetchDatas()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!currentMenu.is_view) {
    return <div>Access denied</div>;
  }

  return (
    <Fragment>
      {contextHolder}
      {contextHolderMsg}
      <CSVLink
        headers={DonwloadHeadersCSV}
        data={DataSourceDownload}
        filename={"export-product.csv"}
        ref={csvLinkRef}
      />
      <BreadcrumbComponent active={"Product"} />
      <Content>
        <Form
          form={form}
          layout={"vertical"}
          onFinish={handleFilter}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="Search Type"
                name="type"
              >
                <Select
                  placeholder="Select a option and change input text above"
                  allowClear
                >
                  <Option value="game">Main</Option>
                  <Option value="item">Variant</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Search By"
                name="searchBy"
              >
                <Select
                  placeholder="Select a option and change input text above"
                  allowClear
                >
                  <Option value="code">Code</Option>
                  <Option value="name">Name</Option>
                  <Option value="description">Description</Option>
                  <Option value="parent">Variant of Game Parent</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Search Data"
                name="searchData"
              >
                <Input placeholder="Search Data" />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="bg-pink-600 hover:bg-pink-700 text-white font-bold">
                  Submit Filter
                </Button>
                <Button type="text" htmlType="button" className="border border-gray-500 text-gray-500 font-bold ml-3" onClick={handleClearFilter}>
                  Clear
                </Button>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Button
                onClick={() => {
                  SetIsModalOpen(true)
                  SetEntitySource({
                    flag: 'ADD',
                    dataSource: {}
                  })
                }}
                className="bg-pink-600 hover:bg-pink-700 text-white font-bold mb-3 float-right" 
              >
                  Add New
              </Button>
              <Button
                onClick={() => handleDownloadData()}
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold mb-3 mr-3 float-right" 
              >
                  Download
              </Button>
            </Col>

          </Row>
        </Form>

        <Table 
          dataSource={DataSource.data}
          pagination={{
            defaultCurrent: DataSource.pagination.page,
            current: DataSource.pagination.page,
            pageSize: DataSource.pagination.per_page,
            total: DataSource.pagination.total,
            onChange: (page:number, pageSize:number) => onChangePage(page, pageSize),
        }}
          scroll={{ x: true }}
          columns={ColumnOloProduct({
            actionSlot: (_: unknown, record: OloProductReponseEntity) => (
              <Space size="middle">
                  <Button
                      type="text"
                      onClick={() => {
                        SetEntitySource({
                          flag: 'EDIT',
                          dataSource: record
                        })
                        SetIsModalOpen(true)
                      }}
                      className="border border-orange-500 text-orange-500"
                  >
                      Edit
                  </Button>

                  <Button
                      type="text"
                      onClick={() => ApiDeleteContent(record)}
                      className="border border-pink-600 text-pink-600"
                  >
                      Delete
                  </Button>
              </Space>
          ),
          })}
        />
      </Content>

      <ProductForm 
        key={EntitySource.dataSource.code}
        flag={EntitySource.flag}
        isTitle={ (EntitySource.flag === 'ADD' ? "Add"  : "Edit") + " Product" }
        isModal={IsModalOpen}
        setIsModal={(val:boolean) => SetIsModalOpen(val)}
        dataSource={EntitySource.dataSource}
        eventPost={APIAddProduct}
        eventPut={APIPutProduct}
      />
    </Fragment>
  )
}

export default Product;
