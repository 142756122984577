export const ColumnProductPublish = ({ actionSlot }: any) => {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Partner',
            dataIndex: 'partner_code',
            key: 'partner_code',
        },
        {
            title: 'Channel',
            dataIndex: 'channel_code',
            key: 'channel_code',
        },
        {
            title: 'Start Periode',
            dataIndex: 'start_date',
            key: 'start_date',
        },
        {
            title: 'End Periode',
            dataIndex: 'end_date',
            key: 'end_date',
        },
        {
            title: 'Status',
            dataIndex: 'status_label',
            key: 'status_label',
        },
        {
            title: 'Created By',
            dataIndex: 'created_by_id',
            key: 'created_by_id',
        },
        {
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Updated Date',
            dataIndex: 'updated_at',
            key: 'updated_at',
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: actionSlot,
        },
      ];
};
  