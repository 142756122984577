import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthGuard from "./routes/AuthGuard";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Orders from "./pages/Orders";
import OrderDetail from "./pages/Orders/OrderDetail";
import Users from "./pages/Users";
import GroupBusiness from "./pages/GroupBusiness";
import GroupBusinessForm from "pages/GroupBusiness/Form";
import BusinessCategory from "pages/BusinessCategory";
import BusinessCategoryForm from "pages/BusinessCategory/Form";
import Partners from "pages/Partners";
import PartnerForm from "pages/Partners/Form";
import Settlement from "pages/Settlement/Settlement";
import SettlementDetail from "pages/Settlement/SettlementDetail";
import UserForm from "pages/Users/Form";
import Roles from "pages/Roles";
import RoleForm from "pages/Roles/Form";
import Customers from "pages/Customers";
import Reports from "pages/Reports";
import ProductVariants from "pages/ProductVariants";
import ProductInventories from "pages/ProductInventory/ProductInventory";
import ProductInventoryForm from "pages/ProductInventory/Form";
import Product from "pages/Product";
import ProductPrice from "pages/ProductPrice";
import ProductPriceSettingForm from "pages/ProductPrice/Form";
import ProductPublish from "pages/ProductPublish";
import ProductPublishForm from "pages/ProductPublish/Form";
import PaymentMethod from "pages/OLO/PaymentMethod/PaymentMethod";
import Channel from "pages/OLO/Channel/Channel";
import OloProduct from "pages/OLO/Product/Product";
import OloProductSKU from "pages/OLO/ProductSKU/ProductSKU";
import OloProductPublish from "pages/OLO/ProductPublish/ProductPublish";
import OloProductPublishForm from "pages/OLO/ProductPublish/ProductPublishForm";

import Unimplemeted from "pages/Unimplemented";

function App() {
  const protectedRoutes = [
    { path: "/cms/unimplemented", Component: <Unimplemeted /> },
    { path: "/cms/dashboard", Component: <Dashboard /> },
    { path: "/cms/orders", Component: <Orders /> },
    { path: "/cms/order-detail/:id/:group", Component: <OrderDetail /> },
    { path: "/cms/customers", Component: <Customers /> },
    { path: "/cms/reports", Component: <Reports /> },
    { path: "/cms/product", Component: <Product /> },
    { path: "/cms/product-variants", Component: <ProductVariants /> },
    {
      path: "/cms/products-inventory",
      Component: <ProductInventories />,
    },
    {
      path: "/cms/products-inventory/view",
      Component: <ProductInventoryForm />,
    },
    {
      path: "/cms/products-inventory/edit",
      Component: <ProductInventoryForm />,
    },
    { path: "/cms/products-price", Component: <ProductPrice /> },
    {
      path: "/cms/products-price/view",
      Component: <ProductPriceSettingForm />,
    },
    { path: "/cms/products-price/add", Component: <ProductPriceSettingForm /> },
    {
      path: "/cms/products-price/edit",
      Component: <ProductPriceSettingForm />,
    },
    { path: "/cms/products-publish", Component: <ProductPublish /> },
    {
      path: "/cms/products-publish/view",
      Component: <ProductPublishForm />,
    },
    {
      path: "/cms/products-publish/add",
      Component: <ProductPublishForm />,
    },
    {
      path: "/cms/products-publish/edit",
      Component: <ProductPublishForm />,
    },
    { path: "/cms/settlement", Component: <Settlement /> },
    { path: "/cms/settlement/:id/:group", Component: <SettlementDetail /> },
    { path: "/cms/partners", Component: <Partners /> },
    { path: "/cms/partners/view", Component: <PartnerForm /> },
    { path: "/cms/partners/add", Component: <PartnerForm /> },
    { path: "/cms/partners/edit", Component: <PartnerForm /> },
    { path: "/cms/group-business", Component: <GroupBusiness /> },
    { path: "/cms/group-business/view", Component: <GroupBusinessForm /> },
    { path: "/cms/group-business/add", Component: <GroupBusinessForm /> },
    { path: "/cms/group-business/edit", Component: <GroupBusinessForm /> },
    { path: "/cms/business-category", Component: <BusinessCategory /> },
    {
      path: "/cms/business-category/view",
      Component: <BusinessCategoryForm />,
    },
    { path: "/cms/business-category/add", Component: <BusinessCategoryForm /> },
    {
      path: "/cms/business-category/edit",
      Component: <BusinessCategoryForm />,
    },
    { path: "/cms/users", Component: <Users /> },
    { path: "/cms/users/view", Component: <UserForm /> },
    { path: "/cms/users/add", Component: <UserForm /> },
    { path: "/cms/users/edit", Component: <UserForm /> },
    { path: "/cms/roles", Component: <Roles /> },
    { path: "/cms/roles/view", Component: <RoleForm /> },
    { path: "/cms/roles/add", Component: <RoleForm /> },
    { path: "/cms/roles/edit", Component: <RoleForm /> },
    { path: "/cms/olo/payment-method", Component: <PaymentMethod /> },
    { path: "/cms/olo/channel", Component: <Channel /> },
    { path: "/cms/olo/product", Component: <OloProduct /> },
    { path: "/cms/olo/product-sku", Component: <OloProductSKU /> },
    { path: "/cms/olo/product-publish", Component: <OloProductPublish /> },
    { path: "/cms/olo/product-publish/form/:id?", Component: <OloProductPublishForm /> },
  ];

  return (
    <Router>
      <Routes>
        <Route path="/cms/login" element={<Login />} />
        <Route path="/" element={<AuthGuard isNotAllowed={true} />} />

        {protectedRoutes.map((item, i) => {
          return (
            <Route
              key={i}
              path={item.path}
              element={<AuthGuard>{item.Component}</AuthGuard>}
            />
          );
        })}
      </Routes>
    </Router>
  );
}

export default App;
