import { Button, Col, Form, Input, Row, Select, Space, Table, message, notification } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnChannel } from "components/Columns/ColumnChannel";
import useCurrentMenu from "customhook/useCurrentMenu";
import { fetchAPI, MainRowsResponse } from "helpers/apiHelpers";
import { failedGetDataMessage } from "helpers/defaultMessage";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import ChannelForm from "./ChannelForm";
import queryString from "query-string";
import BreadcrumbComponent from "components/BreadcrumbComponent";
import { ChannelReponseEntity } from "Entity/ChannelEntity";
import { CSVLink } from "react-csv";
import moment from "moment";

const { Option } = Select;

const DonwloadHeadersCSV = [
  { label: "Code", key: "code" },
  { label: "Name", key: "name" },
  { label: "Description", key: "description" },
  { label: "Status", key: "active_label" },
  { label: "Created At", key: "created_at" },
];

type EntitySourceType = {
  flag: 'ADD' | 'EDIT' | 'DELETE';
  dataSource: ChannelReponseEntity;
}

const InitialEntitySource:EntitySourceType = {
  flag: 'ADD',
  dataSource: {},
}

const Channel = () => {
  const currentMenu = useCurrentMenu();
  const [form] = Form.useForm();
  const [notif, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMsg] = message.useMessage();
  const [IsModalOpen, SetIsModalOpen] = useState(false)
  const [DataSource, SetDataSource] = useState<ChannelReponseEntity[]>([]);
  const [EntitySource, SetEntitySource] = useState<EntitySourceType>(InitialEntitySource)

  const fetchDatas = useCallback(async (query?: {code?:string; name?: string}) => {
    try {
      const params:any = {
        ...query,
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<ChannelReponseEntity> | null = await fetchAPI(
        `olo/channels?${queryString.stringify(params)}`
      );

      const datas = response?.data?.rows?.map((data:ChannelReponseEntity) => {
        return {
          ...data,
          active_label: data.active ? "Active" : "Not Active",
          created_at: data.created_at ? moment(data.created_at).zone(data.created_at).format("DD MMM YYYY HH:mm:ss") : '',
          updated_at: data.updated_at ? moment(data.updated_at).zone(data.updated_at).format("DD MMM YYYY HH:mm:ss") : ''
        };
      }) ?? [];
      SetDataSource(datas)
    } catch (error: any) {
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  const APIAddChannel = useCallback(async (val:any) => {
    const data = {  
        code: val.code,
        name: val.name,
        description: val.description,
        active: val.active ? true : false
    }

    messageApi.loading({
        content: 'Action in progress..',
        duration: 0
    })

    await fetchAPI(`olo/channels`, 'POST', data).then((res:any) => {
        if(res.code === 200 ) {
            notif.success({
                message: 'Success',
                description: "Data Successfully Created"
            })
            messageApi.destroy()
            fetchDatas()
            SetIsModalOpen(false)
        }
    }).catch((err: any) => {
        notif.error({
            message: 'Error',
            description: `${err}`
        })
        messageApi.destroy()
    });
  }, [fetchDatas, SetIsModalOpen, notif, messageApi])

  const APIPutChannel = useCallback(async (val:any) => {
    const data = {
        name: val.name,
        description: val.description,
        active: val.active ? true : false
    }

    messageApi.loading({
        content: 'Action in progress..',
        duration: 0
    })

    await fetchAPI(`olo/channels/${val.code}`, 'PUT', data).then((res:any) => {
      if(res.code === 200 ) {
          notif.success({
              message: 'Success',
              description: "Data Successfully Updated"
          })
          messageApi.destroy()
          fetchDatas()
          SetIsModalOpen(false)
      }
    }).catch((err: any) => {
        notif.error({
            message: 'Error',
            description: `${err}`
        })
        messageApi.destroy()
    });
  }, [fetchDatas, SetIsModalOpen, notif, messageApi])

  const ApiDeleteChannel = async (item:ChannelReponseEntity) => {
    if(window.confirm('Are you sure?')) {

        messageApi.loading({
            content: 'Action in progress..',
            duration: 0
        })

        await fetchAPI(`olo/channels/${item.code}`, 'DELETE').then((res:any) => {
            if(res.code === 200 ) {
                notif.success({
                    message: 'Success',
                    description: "Data Successfully Deleted"
                })
                messageApi.destroy()
              fetchDatas()
              SetIsModalOpen(false)
            }
        }).catch((err: any) => {
            notif.error({
                message: 'Error',
                description: `${err}`
            })
            messageApi.destroy()
        });
    }
  }

  const handleFilter =  (val?: {searchBy?: any; searchData?: string}) => {
    fetchDatas({
      [val?.searchBy]: [val?.searchData],
    });
  }

  const handleClearFilter = () => {
    form.resetFields();
    fetchDatas()
  }

  const handleDownloadData = async () => {
    messageApi.loading({
      content: 'Download in progress..',
      duration: 0
    })

    setTimeout(() => {
      csvLinkRef?.current?.link.click();
      messageApi.destroy()
    }, 1500);
  }

  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  useEffect(() => {
    fetchDatas()
  }, [fetchDatas])

  if (!currentMenu.is_view) {
    return <div>Access denied</div>;
  }

  return (
    <Fragment>
      {contextHolder}
      {contextHolderMsg}
      <CSVLink
        headers={DonwloadHeadersCSV}
        data={DataSource}
        filename={"export-channel.csv"}
        ref={csvLinkRef}
      />
      <BreadcrumbComponent active={"Channel"} />
      <Content>
        <Form
          form={form}
          layout={"vertical"}
          onFinish={handleFilter}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="Search By"
                name="searchBy"
                rules={[{ required: true, message: 'Search By is required'}]}
              >
                <Select
                  placeholder="Select a option and change input text above"
                  allowClear
                >
                  <Option value="code">Code</Option>
                  <Option value="name">Name</Option>
                  <Option value="desc">Description</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Search Data"
                name="searchData"
                rules={[{ required: true, message: 'Search Data is required'}]}
              >
                <Input placeholder="Search Data" />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="bg-pink-600 hover:bg-pink-700 text-white font-bold">
                  Submit Filter
                </Button>
                <Button type="text" htmlType="button" className="border border-gray-500 text-gray-500 font-bold ml-3" onClick={handleClearFilter}>
                  Clear
                </Button>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Button
                onClick={() => {
                  SetIsModalOpen(true)
                  SetEntitySource({
                    flag: 'ADD',
                    dataSource: {}
                  })
                }}
                className="bg-pink-600 hover:bg-pink-700 text-white font-bold mb-3 float-right" 
              >
                  Add New
              </Button>
              <Button
                onClick={handleDownloadData}
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold mb-3 mr-3 float-right" 
              >
                  Download
              </Button>
            </Col>

          </Row>
        </Form>

        <Table 
          dataSource={DataSource} 
          columns={ColumnChannel({
            actionSlot: (_: unknown, record: ChannelReponseEntity) => (
              <Space size="middle">
                  <Button
                      type="text"
                      onClick={() => {
                        SetEntitySource({
                          flag: 'EDIT',
                          dataSource: record
                        })
                        SetIsModalOpen(true)
                      }}
                      className="border border-orange-500 text-orange-500"
                  >
                      Edit
                  </Button>

                  <Button
                      type="text"
                      onClick={() => ApiDeleteChannel(record)}
                      className="border border-pink-600 text-pink-600"
                  >
                      Delete
                  </Button>
              </Space>
          ),
          })}
        />
      </Content>

      <ChannelForm 
        key={EntitySource.dataSource.code}
        flag={EntitySource.flag}
        isTitle={ (EntitySource.flag === 'ADD' ? "Add"  : "Edit") + " Channel" }
        isModal={IsModalOpen}
        setIsModal={(val:boolean) => SetIsModalOpen(val)}
        dataSource={EntitySource.dataSource}
        eventPost={APIAddChannel}
        eventPut={APIPutChannel}
      />
    </Fragment>
  )
}

export default Channel;
