import { ProductPublishPaymentReponseEntity } from "Entity/ProductPublishPaymentEntity";

export const ColumnProductPublishPayment = ({ actionSlot, PriceConfig }: any) => {
    return [
        {
            title: 'Payment',
            dataIndex: 'payment_method_code',
            key: 'payment_method_code',
            className:"w-2/4",
        },
        {
            title: 'Status',
            dataIndex: 'status_label',
            key: 'status_label',
            render: (_: unknown, record: ProductPublishPaymentReponseEntity) => (
                <>{record.active ? 'Active' : 'Non-Active'}</>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            className:"w-1/6",
            render: PriceConfig,
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            className:"!max-w-[150px]",
            render: actionSlot,
        },
      ];
};


  