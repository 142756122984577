import { Button, Checkbox, Col, DatePicker, Flex, Form, FormProps, GetProps, Input, message, Modal, notification, Row, Select} from "antd"
import { ChannelReponseEntity } from "Entity/ChannelEntity";
import { ProductPublishReponseEntity, ProductPublishRequestEntity } from "Entity/ProductPublishEntity";
import { ProductSKUReponseEntity } from "Entity/ProductSKUEntity";
import { fetchAPI, MainResponse, MainRowsResponse } from "helpers/apiHelpers";
import queryString from "query-string";
import { Fragment, useCallback, useEffect, useState } from "react";
import dayjs from 'dayjs';
import ProductPublishPayment from "./ProductPublishPayment";
import BreadcrumbComponent from "components/BreadcrumbComponent";
import { useNavigate, useParams } from "react-router-dom";
import ProductSKU from "../ProductSKU/ProductSKU";

const { RangePicker } = DatePicker;

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
type ProtectedType = {
  paramId?: string;
  disablePaymentOption?: boolean,
  flagForm: 'EDIT' | 'ADD'
}
const ProtectedInitial:ProtectedType = {
  paramId: '',
  disablePaymentOption: true,
  flagForm: 'ADD'
}
const Option = Select

type ListOption = {
  value?: string;
  label?: string
}

const InitialListOption:ListOption = {
  value: '',
  label: ''
}

const ProductPublishForm = () => {
  const RouteParams = useParams();
  const navigate = useNavigate();
  const [messageApi, contextHolderMsg] = message.useMessage();
  const [notif, contextHolder] = notification.useNotification();
  const [form] = Form.useForm<ProductPublishRequestEntity>();
  const [DataProtected, SetDataProtected] = useState<ProtectedType>(ProtectedInitial);
  const [DataSourceChannel, SetDataSourceChannel] = useState<ChannelReponseEntity[]>([]);
  const [IsModalOpenProductSKU, SetIsModalOpenProductSKU] = useState(false)
  const [DataListPartner, SetDataListPartner] = useState<ListOption>(InitialListOption);
  const [DataListGame, SetDataListGame] = useState<ListOption>(InitialListOption);
  const [DataListDenom, SetDataListDenom] = useState<ListOption>(InitialListOption);

  const ApiGetChannel = useCallback(async () => {
    try {
      const params:any = {
        active: true,
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<ChannelReponseEntity> | null = await fetchAPI(
        `olo/channels?${queryString.stringify(params)}`
      );
      SetDataSourceChannel(response?.data?.rows ?? [])
      
    } catch (error: any) {
      SetDataSourceChannel([])
    }
  }, []);

  const ApiGetProductSKU = useCallback(async (id?:number) => {
    try {

      messageApi.loading({
        content: 'Action in progress..',
        duration: 0
      })

      const params:any = {
        id,
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<ProductSKUReponseEntity> | null = await fetchAPI(
        `olo/productSkus?${queryString.stringify(params)}`
      );

      const val:ProductSKUReponseEntity = response?.data.rows[0] ?? {}
      form.setFieldValue("partner_code", val.partner_code)
      form.setFieldValue("game", val.product_code_game)
      form.setFieldValue("product_sku_id", val.id)
      form.setFieldValue("product_code", val.product_code)
      form.setFieldValue("product_sku", val.sku)
      form.setFieldValue("wholesale_price", val.wholesale_price)
      
      SetDataListPartner({value: val?.partner_code, label: val?.partner_name})
      SetDataListGame({value: val?.product_code_game, label: val?.product_name_game})
      SetDataListDenom({value: val?.product_code, label: val?.product_name_denom})

      messageApi.destroy()

    } catch (error: any) {
      messageApi.destroy()
      notif.error({
        message: 'Error',
        description: `${error.message}`
    })
    }
  }, [form, messageApi, notif]);

  const ApiGetPublishDetail = useCallback(async () => {
    if(DataProtected.paramId) {
      try {
        messageApi.loading({
          content: 'Action in progress..',
          duration: 0
        })

        const response: MainRowsResponse<ProductPublishReponseEntity> | null = await fetchAPI(
          `olo/productPublish/${DataProtected.paramId}`
        );
        form.setFieldsValue(response?.data?.rows[0] ?? {})
        form.setFieldValue('date_range', [
          dayjs(response?.data.rows[0].start_date, 'YYYY-MM-DD'), dayjs(response?.data.rows[0].end_date, 'YYYY-MM-DD')
        ])

        await ApiGetProductSKU(response?.data.rows[0].product_sku_id)

        SetDataProtected({
          paramId: RouteParams.id,
          flagForm: RouteParams.id ? 'EDIT' : 'ADD',
          disablePaymentOption: false
        })

        messageApi.destroy()
      } catch (error: any) {
        messageApi.destroy()
        notif.error({
          message: 'Error',
          description: `${error.message}`
        })
      }
    }
    
  }, [ApiGetProductSKU, DataProtected.paramId, RouteParams.id, form, messageApi, notif]);

  useEffect(() => {
    SetDataProtected({
      paramId: RouteParams.id,
      flagForm: RouteParams.id ? 'EDIT' : 'ADD',
      disablePaymentOption: true
    })
    ApiGetChannel()
  }, [ApiGetChannel, RouteParams.id])

  useEffect(() => {
    ApiGetPublishDetail()
  }, [ApiGetPublishDetail])

  const APIAddProductPublish = useCallback(async (val:ProductPublishRequestEntity) => {
    const data = {  
      partnerCode: val.partner_code,
      channelCode: val.channel_code,
      productCode: val.product_code,
      productSkuId: Number(val.product_sku_id),
      startDate: val.start_date,
      endDate: val.end_date,
      status: val.status ? 1 : 0

    }

    messageApi.loading({
        content: 'Action in progress..',
        duration: 0
    })

    await fetchAPI(`olo/productPublish`, 'POST', data).then((res:any) => {
        const response:MainResponse<ProductPublishReponseEntity> | null = res
        if(response?.code === 200 ) {
            notif.success({
                message: 'Success',
                description: "Data Successfully Created"
            })  
            SetDataProtected({
              ...DataProtected,
              flagForm: 'EDIT',
              paramId: response.data.id,
              disablePaymentOption: false
            })
            messageApi.destroy()
            navigate(`/cms/olo/product-publish/form/${response.data.id}`)
        }
    }).catch((err: any) => {
        notif.error({
            message: 'Error',
            description: `${err.message}`
        })
        messageApi.destroy()
    });
  }, [messageApi, notif, DataProtected, navigate])

  const APIPutProductPublish = useCallback(async (val:ProductPublishRequestEntity) => {
    const data = {
      partnerCode: val.partner_code,
      channelCode: val.channel_code,
      productCode: val.product_code,
      productSkuId: Number(val.product_sku_id),
      startDate: val.start_date,
      endDate: val.end_date,
      status: val.status ? 1 : 0
    }

    messageApi.loading({
        content: 'Action in progress..',
        duration: 0
    })

    await fetchAPI(`olo/productPublish/${val.id}`, 'PUT', data).then((res:any) => {
      if(res.code === 200 ) {
          notif.success({
              message: 'Success',
              description: "Data Successfully Updated"
          })
          messageApi.destroy()
      }
    }).catch((err: any) => {
        notif.error({
            message: 'Error',
            description: `${err.message}`
        })
        messageApi.destroy()
    });
  }, [messageApi, notif])

  const getChooseProductSKU = (val: ProductSKUReponseEntity) => {
    SetIsModalOpenProductSKU(false)
    form.setFieldValue("partner_code", val.partner_code)
    form.setFieldValue("game", val.product_code_game)
    form.setFieldValue("product_sku_id", val.id)
    form.setFieldValue("product_code", val.product_code)
    form.setFieldValue("product_sku", val.sku)
    form.setFieldValue("wholesale_price", val.wholesale_price)

    SetDataListPartner({value: val?.partner_code, label: val?.partner_name})
    SetDataListGame({value: val?.product_code_game, label: val?.product_name_game})
    SetDataListDenom({value: val?.product_code, label: val?.product_name_denom})
  }

  const onFinish = (values:any) => {
      values.start_date = dayjs(values.date_range?.[0]).format('YYYY-MM-DD')
      values.end_date = dayjs(values.date_range?.[1]).format('YYYY-MM-DD')

      if(DataProtected.flagForm === 'ADD') {
        APIAddProductPublish(values)
      } 
      if(DataProtected.flagForm === 'EDIT') {
        APIPutProductPublish(values)
      } 
  };
  
  const onFinishFailed: FormProps<ProductPublishRequestEntity>['onFinishFailed'] = (errorInfo) => {
      console.log('Failed:', errorInfo);
  };

  const closeForm = () => {
    form.resetFields();
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().endOf('day').subtract(1, 'day');
  };
  
  return (
    <Fragment>
      {contextHolderMsg}
      {contextHolder}
      <BreadcrumbComponent active={"Product Publish Form"} />
      <Button
          onClick={() => navigate('/cms/olo/product-publish')}
          className="bg-gray-200 hover:bg-gray-300 font-medium text-gray-800 py-2 px-4 rounded inline-flex items-center mb-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-4 h-4"
        >
          <path
            fill-rule="evenodd"
            d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
            clip-rule="evenodd"
          />
        </svg> Back
      </Button>
      <Row justify="center" align="middle" style={{ marginTop: 20 }} className="cs-modal-data">
          <Col span={20}>
              <Form 
                  name="basic"
                  form={form} 
                  layout="vertical" 
                  autoComplete="off" 
                  labelCol={{ span: 12 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
              >
                  <Form.Item<ProductPublishRequestEntity> name="id" label="id" hidden>
                    <Input />
                  </Form.Item>

                  <Row>
                    <Col span={24} className="pb-5">
                      <Button className="" onClick={() => SetIsModalOpenProductSKU(true)}>Choose Product SKU</Button>
                    </Col>

                    <Col span={12} className="px-2">
                      <Form.Item<ProductPublishRequestEntity> name="channel_code" label="Channel" rules={[{ required: true }]} >
                        <Select
                          placeholder="Select a option and change input text above"
                          allowClear
                        >
                          {DataSourceChannel?.map((item, i) => (
                            <>
                              <Option value={`${item.code}`}>{item.name}</Option>
                            </>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12} className="px-2">
                      <Form.Item<ProductPublishRequestEntity> name="partner_code" label="Partner" rules={[{ required: true }]} >
                        <Select
                          placeholder="Select a option and change input text above"
                          allowClear
                          disabled
                        >
                          <Option value={`${DataListPartner.value}`}>{DataListPartner.label}</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12} className="px-2">
                      <Form.Item<ProductPublishRequestEntity> name="game" label="Game" rules={[{ required: true }]} >
                        <Select
                          placeholder="Select a option and change input text above"
                          allowClear
                          disabled
                        >
                          <Option value={`${DataListGame.value}`}>{DataListGame.label}</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12} className="px-2">
                      <Form.Item<ProductPublishRequestEntity> name="product_code" label="Denom" rules={[{ required: true }]} >
                        <Select
                          placeholder="Select a option and change input text above"
                          allowClear
                          disabled
                        >
                          <Option value={`${DataListDenom.value}`}>{DataListDenom.label}</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12} className="px-2">
                      <Form.Item<ProductPublishRequestEntity> name="product_sku_id" label="SKU ID" rules={[{ required: true }]} hidden>
                        <Input disabled />
                      </Form.Item>
                      <Form.Item<ProductPublishRequestEntity> name="product_sku" label="SKU Name" rules={[{ required: true }]} >
                        <Input disabled/>
                      </Form.Item>
                    </Col>

                    <Col span={12} className="px-2">
                      <Form.Item<ProductPublishRequestEntity> name="wholesale_price" label="Wholesale Price" rules={[{ required: true }]} >
                        <Input type="number" min={0} disabled />
                      </Form.Item>
                    </Col>
                    
                    <Col span={12} className="px-2">
                      <Form.Item<ProductPublishRequestEntity> name="date_range" label="Date Periode" rules={[{ required: true }]} >
                        <RangePicker 
                          className="w-full"
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12} className="px-2">
                      <Form.Item<ProductPublishRequestEntity> name="status" label="Status" valuePropName="checked">
                        <Checkbox></Checkbox>
                      </Form.Item>
                    </Col>

                  </Row>
                  <Form.Item>
                      <Flex wrap justify="flex-end" gap="middle" style={{marginTop: '20px'}}>
                          {DataProtected.disablePaymentOption && (
                            <Button onClick={closeForm} style={{border: '1px solid #2B3674', color: '#2B3674'}}>Reset</Button>
                          )}
                          <Button htmlType="submit" style={{border: '1px solid #2B3674', backgroundColor: '#2B3674', color: 'white'}}>Submit</Button>
                      </Flex>
                  </Form.Item>
              </Form>
              <ProductPublishPayment 
                productPublishId={DataProtected.paramId}
                disabled={DataProtected.disablePaymentOption}
                sourceProductPublish={form.getFieldsValue()}
              />
          </Col>
      </Row>

      <Modal 
        open={IsModalOpenProductSKU} 
        footer={null}
        onCancel={() => SetIsModalOpenProductSKU(false)}
        width={1000}
      >
        <ProductSKU 
          selection={{
            type: 'radio',
            getSubmitRow: (val:any) => getChooseProductSKU(val)
          }}
        />
      </Modal>

    </Fragment>
  )
}

export default ProductPublishForm;
