import { Button, Space, Table, message, notification } from "antd";
import { Content } from "antd/es/layout/layout";
import { ProductPublishPaymentReponseEntity, ProductPublishPaymentRequestEntity } from "Entity/ProductPublishPaymentEntity";
import { fetchAPI, MainRowsResponse } from "helpers/apiHelpers";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import queryString from "query-string";
import { ColumnProductPublishPayment } from "components/Columns/ColumnProductPublishPayment";
import ProductPublishPaymentForm from "./ProductPublishPaymentForm";
import ProductPublishPriceConfigForm from "./ProductPublishPriceConfigForm";
import { ProductPublishPriceConfigRequestEntity } from "Entity/ProductPublishPriceConfigEntity";
import { ProductPublishRequestEntity } from "Entity/ProductPublishEntity";

type EntitySourceType = {
  flag: 'ADD' | 'EDIT' | 'DELETE';
  dataSource: ProductPublishPaymentReponseEntity;
}

type EntitySourceTypePriceConfig = {
  flag: 'ADD' | 'EDIT' | 'DELETE';
  dataSource: ProductPublishPriceConfigRequestEntity;
}

const InitialEntitySource:EntitySourceType = {
  flag: 'ADD',
  dataSource: {},
}

type Props = {
  productPublishId?: string;
  disabled?: boolean;
  sourceProductPublish: ProductPublishRequestEntity;
}

const ProductPublishPayment = (props:Props) => {
  const [notif, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMsg] = message.useMessage();
  const [IsModalOpen, SetIsModalOpen] = useState(false)
  const [IsModalOpenPriceConfig, SetIsModalOpenPriceConfig] = useState(false)
  const [DataSource, SetDataSource] = useState<ProductPublishPaymentReponseEntity[]>([]);
  const [EntitySource, SetEntitySource] = useState<EntitySourceType>(InitialEntitySource)
  const [EntitySourcePriceConfig, SetEntitySourcePriceConfig] = useState<EntitySourceTypePriceConfig>(InitialEntitySource)

  const fetchDatas = useCallback(async (query?: {productPublishId?:string;}) => {
    try {

      messageApi.loading({
        content: 'Action in progress..',
        duration: 0
      })

      const params:any = {
        ...query,
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<ProductPublishPaymentReponseEntity> | null = await fetchAPI(
        `olo/product-publish/payments?${queryString.stringify(params)}`
      );
      
      SetDataSource(response?.data.rows ?? [])
      messageApi.destroy()
    } catch (error: any) {
      messageApi.destroy()
      notif.error({
        message: 'Error',
        description: `${error.message}`
    })
    }
  }, [messageApi, notif]);

  const APIAddProductPublishPayment = useCallback(async (val:ProductPublishPaymentRequestEntity) => {
    const data = {  
      paymentMethodCode: val.payment_method_code?.toString(),    
      productPublishId: val.product_publish_id,    
      active: val.active ?? false
    }

    messageApi.loading({
        content: 'Action in progress..',
        duration: 0
    })

    await fetchAPI(`olo/product-publish/payments`, 'POST', data).then((res:any) => {
        if(res.code === 200 ) {
            notif.success({
                message: 'Success',
                description: res.data
            })
            messageApi.destroy()
            fetchDatas({productPublishId: props.productPublishId })
            SetIsModalOpen(false)
        }
    }).catch((err: any) => {
        notif.error({
            message: 'Error',
            description: `${err.message}`
        })
        messageApi.destroy()
    });
  }, [messageApi, notif, fetchDatas, props.productPublishId])

  const APIPutProductPublishPayment = useCallback(async (val:ProductPublishPaymentRequestEntity) => {
    const data = {
      paymentMethodCode: val.payment_method_code?.toString(),    
      productPublishId: val.product_publish_id,    
      active: val.active ?? false
    }

    messageApi.loading({
        content: 'Action in progress..',
        duration: 0
    })

    await fetchAPI(`olo/product-publish/payments/${val.id}`, 'PUT', data).then((res:any) => {
      if(res.code === 200 ) {
          notif.success({
              message: 'Success',
              description: res.data
          })
          messageApi.destroy()
          fetchDatas({productPublishId: props.productPublishId})
          SetIsModalOpen(false)
      }
    }).catch((err: any) => {
        notif.error({
            message: 'Error',
            description: `${err.message}`
        })
        messageApi.destroy()
    });
  }, [messageApi, notif, fetchDatas, props.productPublishId])

  const ApiDeleteContent = async (item:ProductPublishPaymentReponseEntity) => {
    if(window.confirm('Are you sure?')) {

        messageApi.loading({
            content: 'Action in progress..',
            duration: 0
        })

        await fetchAPI(`olo/product-publish/payments/${item.id}`, 'DELETE').then((res:any) => {
            if(res.code === 200 ) {
                notif.success({
                    message: 'Success',
                    description: res.data
                })
                messageApi.destroy()
                fetchDatas({productPublishId: props.productPublishId})
              SetIsModalOpen(false)
            }
        }).catch((err: any) => {
            notif.error({
                message: 'Error',
                description: err.response.data.message
            })
            messageApi.destroy()
        });
    }
  }

  useEffect(() => {
    if(props.productPublishId) {
      fetchDatas({productPublishId: props.productPublishId})
    }
  }, [fetchDatas, props.productPublishId])

  return (
    <Fragment>
      {contextHolder}
      {contextHolderMsg}
      <Content>
        <Button
          onClick={() => {
            SetIsModalOpen(true)
            SetEntitySource({
              flag: 'ADD',
              dataSource: props.productPublishId ? {product_publish_id: Number(props.productPublishId)} : {}
            })
          }}
          disabled={props.disabled}
          className="bg-pink-600 hover:bg-pink-700 text-white font-bold mb-3" 
        >
            Add Payment Option
        </Button>

        <Table 
          dataSource={DataSource} 
          pagination={{
            pageSize: 5
          }}
          columns={ColumnProductPublishPayment({
            actionSlot: (_: unknown, record: ProductPublishPaymentReponseEntity) => (
              <Space size="middle">
                <Button
                    type="text"
                    onClick={() => {
                      SetEntitySource({
                        flag: 'EDIT',
                        dataSource: record
                      })
                      SetIsModalOpen(true)
                    }}
                    className="border border-orange-500 text-orange-500"
                >
                    Edit
                </Button>
                <Button
                    type="text"
                    onClick={() => ApiDeleteContent(record)}
                    className="border border-pink-600 text-pink-600"
                >
                    Delete
                </Button>
              </Space>
            ),
            PriceConfig: (_: unknown, record: ProductPublishPaymentReponseEntity) => (
              <Button
                  type="link"
                  onClick={() => {
                    SetEntitySourcePriceConfig({
                      flag: 'EDIT',
                      dataSource: {
                        channel_code: props.sourceProductPublish.channel_code,
                        partner_code: props.sourceProductPublish.partner_code,
                        payment_publish_id: Number(record.id),
                        payment_publish_code_label: record.payment_method_code,
                        publish_id_label: record.product_publish_id,
                      }
                    })
                    SetIsModalOpenPriceConfig(true)
                  }}
              >
                  Price Config
              </Button>
            )
          })}
        />
      </Content>

      <ProductPublishPaymentForm 
        key={EntitySource.dataSource.id}
        flag={EntitySource.flag}
        isTitle={ (EntitySource.flag === 'ADD' ? "Add"  : "Edit") + " Form Register Payment Option" }
        isModal={IsModalOpen}
        setIsModal={(val:boolean) => SetIsModalOpen(val)}
        dataSource={EntitySource.dataSource}
        eventPost={APIAddProductPublishPayment}
        eventPut={APIPutProductPublishPayment}
      />

    <ProductPublishPriceConfigForm 
        key={EntitySourcePriceConfig.dataSource.payment_publish_id}
        flag={EntitySourcePriceConfig.flag}
        isModal={IsModalOpenPriceConfig}
        setIsModal={(val:boolean) => SetIsModalOpenPriceConfig(val)}
        dataSource={EntitySourcePriceConfig.dataSource}
      />
    </Fragment>
  )
}

export default ProductPublishPayment;
