import { PaymentMethodReponseEntity } from "Entity/PaymentMethodEntity";
import moment from "moment";

export const ColumnPaymentMethod = ({ actionSlot }: any) => {
    return [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'age',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'active_label',
            key: 'active_label',
        },
        {
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (_: unknown, record: PaymentMethodReponseEntity) => (
                <>{record.created_at ? moment(record.created_at).zone(record.created_at).format("DD MMM YYYY HH:mm") : ''}</>
            ),
        },
        {
            title: 'Updated Date',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (_: unknown, record: PaymentMethodReponseEntity) => (
                <>{record.updated_at ? moment(record.updated_at).zone(record.updated_at).format("DD MMM YYYY HH:mm") : ''}</>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: actionSlot,
        },
      ];
};
  