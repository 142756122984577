/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode } from "react";

interface ContentProps {
  isSidebarVisible: boolean;
  children?: ReactNode;
}

const Contents: React.FC<ContentProps> = ({ isSidebarVisible, children }) => {
  return (
    <div className="flex overflow-hidden bg-white pt-16">
      <div
        id="main-content"
        className={`h-full w-full relative overflow-y-auto ${
          isSidebarVisible ? "lg:ml-64" : ""
        }`}
      >
        <main className="mb-14 px-5 py-5">{children}</main>

        {/* Footer  */}
        <div className="fixed bottom-0 w-full bg-gray-50 mt-10">
          <p className="text-sm text-gray-500 my-4 pl-2">
            &copy; 2024{" "}
            <a
              href="#"
              className="hover:underline my-0 mx-auto"
              target="_blank"
            >
              Smartfren
            </a>
            . All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Contents;
