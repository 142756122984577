import { Button, Col, Form, Input, Row, Select, Space, Table, TableProps, message, notification } from "antd";
import type { FormProps } from 'antd';
import { Content } from "antd/es/layout/layout";
import { ColumnProductSKU } from "components/Columns/ColumnProductSKU";
import useCurrentMenu from "customhook/useCurrentMenu";
import { ProductSKUReponseEntity, ProductSKURequestEntity } from "Entity/ProductSKUEntity";
import { fetchAPI, MainRowsResponse } from "helpers/apiHelpers";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import ProductSKUForm from "./ProductSKUForm";
import queryString from "query-string";
import BreadcrumbComponent from "components/BreadcrumbComponent";
import { CSVLink } from "react-csv";
import moment from "moment";
import usePrivilegedUsers from "customhook/usePrivilegedUsers";
import { PartnerData } from "pages/Partners";

const { Option } = Select;

const DonwloadHeadersCSV = [
  { label: "Product Code", key: "product_code" },
  { label: "Partner Code", key: "partner_code" },
  { label: "SKU", key: "sku" },
  { label: "Wholesale Price", key: "wholesale_price" },
  { label: "Status", key: "active_label" },
  { label: "Created At", key: "created_at" },
];

type EntitySourceType = {
  flag: 'ADD' | 'EDIT' | 'DELETE';
  dataSource: ProductSKUReponseEntity;
}

type FilterFieldType = {
  searchBy: string;
  searchData?: string;
  partnerCode?: string;
};

const InitialEntitySource:EntitySourceType = {
  flag: 'ADD',
  dataSource: {},
}

interface Props {
  selection?: {
    type: string;
    getSubmitRow: Function
  }
}

const ProductSKU = (props:Props) => {
  const currentMenu = useCurrentMenu();
  const [form] = Form.useForm<FilterFieldType>();
  const [notif, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMsg] = message.useMessage();
  const [IsModalOpen, SetIsModalOpen] = useState(false)
  const [DataSource, SetDataSource] = useState<ProductSKUReponseEntity[]>([]);
  const [DataSourcePartner, SetDataSourcePartner] = useState<PartnerData[]>([]);
  const [EntitySource, SetEntitySource] = useState<EntitySourceType>(InitialEntitySource)
  const [DataRowSelected, SetDataRowSelected] = useState<ProductSKUReponseEntity>({})
  const PrivilegedUsers = usePrivilegedUsers();

  const fetchDatas = useCallback(async (query?: {code?:string; name?: string}) => {
    try {

      messageApi.loading({
        content: 'Action in progress..',
        duration: 0
      })

      const params:any = {
        ...query,
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<ProductSKUReponseEntity> | null = await fetchAPI(
        `olo/productSkus?${queryString.stringify(params)}`
      );

      const datas = response?.data?.rows?.map((data:ProductSKUReponseEntity) => {
          return {
            ...data,
            key: data.id,
            status: (data.status === 1) ? true : false,
            status_label: (data.status === 1) ? "Active" : "Draft",
            created_at: data.created_at ? moment(data.created_at).zone(data.created_at).format("DD MMM YYYY HH:mm") : '',
            updated_at: data.updated_at ? moment(data.updated_at).zone(data.updated_at).format("DD MMM YYYY HH:mm") : ''
          };
        }) ?? [];
      
        SetDataSource(datas)
        messageApi.destroy()
    } catch (error: any) {
      messageApi.destroy()
      notif.error({
        message: 'Error',
        description: `${error.message}`
    })
    }
  }, [messageApi, notif]);

  const ApiGetPartner = useCallback(async () => {
    if (Object.keys(PrivilegedUsers?.partners).length) {
      try {
        const params = `?inCode=${PrivilegedUsers.partners.toString()}`
        const response: MainRowsResponse<PartnerData> | null = await fetchAPI(`partners${params}`);

        SetDataSourcePartner(response?.data?.rows ?? [])
      } catch (error: any) {
        SetDataSourcePartner([])
      }
    }

  }, [PrivilegedUsers]);

  const APIAddProductSKU = useCallback(async (val:ProductSKURequestEntity) => {
    const data = {  
      partnerCode: val.partner_code,
      productCode: val.product_code,
      sku: val.sku,
      wholesalePrice: Number(val.wholesale_price),
      status: val.status ? 1 : 0
    }

    messageApi.loading({
        content: 'Action in progress..',
        duration: 0
    })

    await fetchAPI(`olo/productSkus`, 'POST', data).then((res:any) => {
        if(res.code === 200 ) {
            notif.success({
                message: 'Success',
                description: res.data
            })
            messageApi.destroy()
            fetchDatas()
            SetIsModalOpen(false)
        }
    }).catch((err: any) => {
        notif.error({
            message: 'Error',
            description: `${err.message}`
        })
        messageApi.destroy()
    });
  }, [fetchDatas, SetIsModalOpen, notif, messageApi])

  const APIPutProductSKU = useCallback(async (val:ProductSKURequestEntity) => {
    const data = {
      partnerCode: val.partner_code,
      productCode: val.product_code,
      sku: val.sku,
      wholesalePrice: Number(val.wholesale_price),
      status: val.status ? 1 : 0
    }

    messageApi.loading({
        content: 'Action in progress..',
        duration: 0
    })

    await fetchAPI(`olo/productSkus/${val.id}`, 'PUT', data).then((res:any) => {
      if(res.code === 200 ) {
          notif.success({
              message: 'Success',
              description: res.data
          })
          messageApi.destroy()
          fetchDatas()
          SetIsModalOpen(false)
      }
    }).catch((err: any) => {
        notif.error({
            message: 'Error',
            description: `${err.message}`,
            duration: 5
        })
        messageApi.destroy()
    });
  }, [fetchDatas, SetIsModalOpen, notif, messageApi])

  const ApiDeleteContent = async (item:ProductSKUReponseEntity) => {
    if(window.confirm('Are you sure?')) {

        messageApi.loading({
            content: 'Action in progress..',
            duration: 0
        })

        await fetchAPI(`olo/productSkus/${item.id}`, 'DELETE').then((res:any) => {
            if(res.code === 200 ) {
                notif.success({
                    message: 'Success',
                    description: res.data
                })
                messageApi.destroy()
              fetchDatas()
              SetIsModalOpen(false)
            }
        }).catch((err: any) => {
            notif.error({
                message: 'Error',
                description: err.response.data.message
            })
            messageApi.destroy()
        });
    }
  }

  const handleFilter:FormProps<FilterFieldType>['onFinish'] = (val) => {
    const query = {}
    if(val.searchBy && val.searchData) {
      Object.assign(query, {[val?.searchBy]: [val?.searchData.trim()]})
    }

    if(val.partnerCode) {
      Object.assign(query, {'partnerCode': [val?.partnerCode]})
    }

    fetchDatas(query);
  }
  
  const handleClearFilter = () => {
    form.resetFields();
    fetchDatas()
  }

  const handleDownloadData = async () => {
    messageApi.loading({
      content: 'Download in progress..',
      duration: 0
    })

    setTimeout(() => {
      csvLinkRef?.current?.link.click();
      messageApi.destroy()
    }, 1500);
  }

  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  useEffect(() => {
    fetchDatas()
    ApiGetPartner()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDatas])

  // if (!currentMenu.is_view) {
  //   return <div>Access denied</div>;
  // }

  const rowSelection: TableProps<ProductSKUReponseEntity>['rowSelection'] = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ProductSKUReponseEntity[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (val:ProductSKUReponseEntity) => {
      SetDataRowSelected(val)
    },
  };

  return (
    <Fragment>
      {contextHolder}
      {contextHolderMsg}
      <CSVLink
        headers={DonwloadHeadersCSV}
        data={DataSource}
        filename={"export-product-sku.csv"}
        ref={csvLinkRef}
      />
      <BreadcrumbComponent active={"Product SKU"} />
      <Content>
        <Form
          form={form}
          layout={"vertical"}
          onFinish={handleFilter}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="Search By"
                name="searchBy"
              >
                <Select
                  placeholder="Select a option and change input text above"
                  allowClear
                >
                  <Option value="productCode">Product Code</Option>
                  <Option value="sku">SKU</Option>
                  <Option value="productCodeGame">Game</Option>
                  <Option value="productCodeDenom">Denom</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Search Data"
                name="searchData"
              >
                <Input placeholder="Search Data" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Row>
                <Col span={6}>
                  <Form.Item
                    label="Partner"
                    name="partnerCode"
                  >
                    <Select
                      placeholder="Select a option and change input text above"
                      allowClear
                    >
                      {DataSourcePartner.map(item => (
                        <Option value={item.partner_code}>{item.partner_name}</Option>  
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={18}>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="bg-pink-600 hover:bg-pink-700 text-white font-bold">
                  Submit Filter
                </Button>
                <Button type="text" htmlType="button" className="border border-gray-500 text-gray-500 font-bold ml-3" onClick={handleClearFilter}>
                  Clear Filter
                </Button>
              </Form.Item>
            </Col>

            <Col span={6}>
              {currentMenu.is_create && (
                <Button
                  onClick={() => {
                    SetIsModalOpen(true)
                    SetEntitySource({
                      flag: 'ADD',
                      dataSource: {}
                    })
                  }}
                  className="bg-pink-600 hover:bg-pink-700 text-white font-bold mb-3 float-right" 
                >
                    Add New
                </Button>
              )}
              {currentMenu.is_download && (
                <Button
                  onClick={handleDownloadData}
                  className="bg-gray-600 hover:bg-gray-700 text-white font-bold mb-3 mr-3 float-right" 
                >
                    Download
                </Button>
              )}
              {props.selection?.getSubmitRow && (
                <Button
                  className="mb-3 mr-3 float-right" 
                  onClick={() => props.selection?.getSubmitRow(DataRowSelected)}
                >
                    Submit Choose
                </Button>
              )}
            </Col>
          </Row>
        </Form>

        <Table 
          rowSelection={props.selection?.type ? { type: 'radio', ...rowSelection } : undefined}
          dataSource={DataSource} 
          scroll={{ x: true }}
          columns={ColumnProductSKU({
            actionSlot: (_: unknown, record: ProductSKUReponseEntity) => (
              <Space size="middle">
                {currentMenu.is_edit && (
                  <Button
                      type="text"
                      onClick={() => {
                        SetEntitySource({
                          flag: 'EDIT',
                          dataSource: record
                        })
                        SetIsModalOpen(true)
                      }}
                      className="border border-orange-500 text-orange-500"
                  >
                      Edit
                  </Button>
                )}
                {currentMenu.is_delete && (
                  <Button
                      type="text"
                      onClick={() => ApiDeleteContent(record)}
                      className="border border-pink-600 text-pink-600"
                  >
                      Delete
                  </Button>
                )}
              </Space>
          ),
          })}
        />
      </Content>

      <ProductSKUForm 
        key={EntitySource.dataSource.id}
        flag={EntitySource.flag}
        isTitle={ (EntitySource.flag === 'ADD' ? "Add"  : "Edit") + " Product SKU" }
        isModal={IsModalOpen}
        setIsModal={(val:boolean) => SetIsModalOpen(val)}
        dataSource={EntitySource.dataSource}
        eventPost={APIAddProductSKU}
        eventPut={APIPutProductSKU}
      />
    </Fragment>
  )
}

export default ProductSKU;
