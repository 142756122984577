import { Button, Checkbox, Col, Flex, Form, FormProps, Input, Modal, Row, Typography } from "antd"
import TextArea from "antd/es/input/TextArea";
import { ChannelRequestEntity } from "Entity/ChannelEntity";
import { useCallback, useEffect, useState } from "react";

type Props = {
    flag: 'ADD' | 'EDIT' | 'DELETE';
    isTitle: string;
    isModal: boolean;
    setIsModal: Function;
    dataSource: any;
    eventPost?: Function;
    eventPut?: Function;
}

const ChannelForm = (props:Props) => {
  const [form] = Form.useForm<ChannelRequestEntity>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { Title } = Typography

  const onFinish = (values:any) => {
      if(props.flag === 'ADD') {
          props.eventPost?.(values)
      } 
      if(props.flag === 'EDIT') {
          props.eventPut?.(values)
      } 
  };
  
  const onFinishFailed: FormProps<ChannelRequestEntity>['onFinishFailed'] = (errorInfo) => {
      console.log('Failed:', errorInfo);
  };
  
  const handleMapDataSource = useCallback(() => {
      if(props.flag === 'EDIT') {
          form.setFieldsValue(props.dataSource)
      }
      if(props.flag === 'ADD') {
          form.resetFields();
      }
  }, [props.flag, props.dataSource, form])

  useEffect(() => {
    setIsModalOpen(props.isModal)
    handleMapDataSource()
  }, [props.isModal, handleMapDataSource])

  const closeForm = () => {
    setIsModalOpen(false);
    props.setIsModal(false);
    form.resetFields();
  };

  return (
    <Modal open={isModalOpen} onCancel={closeForm} footer={null} width={700} maskClosable={false}>
      <Row>
          <Col span={24}>
              <Title
                  className="text-blue-d"
                  style={{ fontSize: "18px", textAlign: 'center' }}
              >
                  {props.isTitle}
              </Title>
          </Col>
      </Row>
      <Row justify="center" align="middle" style={{ marginTop: 20 }} className="cs-modal-data">
          <Col span={24}>
              <Form 
                  name="basic"
                  form={form} 
                  layout="vertical" 
                  autoComplete="off" 
                  labelCol={{ span: 8 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
              >
                  <Form.Item name="code" label="Code" rules={[{ required: true }]} >
                    <Input disabled={props.flag === 'EDIT' ? true : false} />
                  </Form.Item>

                  <Form.Item name="name" label="Name" rules={[{ required: true }]} >
                    <Input />
                  </Form.Item>

                  <Form.Item name="description" label="Description" rules={[{ required: true }]} >
                    <TextArea rows={4} />
                  </Form.Item>

                  <Form.Item name="active" label="Active" valuePropName="checked">
                    <Checkbox></Checkbox>
                  </Form.Item>

                  <Form.Item>
                      <Flex wrap justify="flex-end" gap="middle" style={{marginTop: '20px'}}>
                          <Button onClick={closeForm} style={{border: '1px solid #2B3674', color: '#2B3674'}}>Close</Button>
                          <Button htmlType="submit" style={{border: '1px solid #2B3674', backgroundColor: '#2B3674', color: 'white'}}>Submit</Button>
                      </Flex>
                  </Form.Item>
              </Form>
          </Col>
      </Row>
    </Modal>
  )
}

export default ChannelForm;
