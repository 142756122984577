import { TableColumnsType } from "antd";
import { ProductSKUReponseEntity } from "Entity/ProductSKUEntity";

export const ColumnProductSKU = ({ actionSlot }: any) => {
    const columns: TableColumnsType<ProductSKUReponseEntity> = [
        {
            title: 'Partner Code',
            dataIndex: 'partner_code',
            key: 'partner_code',
            className:"whitespace-nowrap"
        },
        {
            title: 'Partner Name',
            dataIndex: 'partner_name',
            key: 'partner_name',
            className:"whitespace-nowrap"
        },
        {
            title: 'Game Code',
            dataIndex: 'product_code_game',
            key: 'product_code_game',
            className:"whitespace-nowrap"
        },
        {
            title: 'Game Name',
            dataIndex: 'product_name_game',
            key: 'product_name_game',
            className:"whitespace-nowrap"
        },
        {
            title: 'Product Code',
            dataIndex: 'product_code',
            key: 'product_code',
            className:"whitespace-nowrap"
        },
        {
            title: 'Denom',
            dataIndex: 'product_name_denom',
            key: 'product_name_denom',
            className:"whitespace-nowrap"
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            className:"whitespace-nowrap"
        },
        {
            title: 'Wholesale Price',
            dataIndex: 'wholesale_price',
            key: 'wholesale_price',
            className:"whitespace-nowrap"
        },
        {
            title: 'Status',
            dataIndex: 'status_label',
            key: 'status_label',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            className:"whitespace-nowrap"
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
            className:"whitespace-nowrap"
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: actionSlot,
        },
    ];

    return columns
};
  