import { OloProductReponseEntity } from "Entity/OloProductEntity";

export const ColumnOloProduct = ({ actionSlot }: any) => {
    return [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            className:"whitespace-nowrap"
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'age',
            className:"whitespace-nowrap"
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            className:"whitespace-nowrap",
            render: (_: unknown, record: OloProductReponseEntity) => (
                <>{record.type === 'game' ? "Main" : "Variant"}</>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            className:"!min-w-[200px]",
        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            className:"whitespace-nowrap",
            render: (_: unknown, record: OloProductReponseEntity) => (
                <>{record.active ? "Active" : "Not Active"}</>
            ),
        },
        {
            title: 'Parent',
            dataIndex: 'parent',
            key: 'parent',
            className:"whitespace-nowrap"
        },
        {
            title: 'Images',
            dataIndex: 'image_external_url',
            key: 'image_external_url',
            className:"!min-w-[150px] !max-w-[150px]",
            render: (_: unknown, record: OloProductReponseEntity) => (
                <><img src={record.image_external_url as string} alt={''} className="max-w-[100px]"/></>
            ),
        },
        {
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            className:"whitespace-nowrap"
        },
        {
            title: 'Updated Date',
            dataIndex: 'updated_at',
            key: 'updated_at',
            className:"whitespace-nowrap"
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: actionSlot,
        },
      ];
};
  