import { Button, Col, DatePicker, Flex, Form, FormProps, Input, message, Modal, notification, Row, Select, Space, Switch, Table, Typography } from "antd"
import { ProductPublishPaymentRequestEntity } from "Entity/ProductPublishPaymentEntity";import { Fragment, useCallback, useEffect, useState } from "react";
import { ProductPublishPriceConfigReponseEntity, ProductPublishPriceConfigRequestEntity } from "Entity/ProductPublishPriceConfigEntity";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import queryString from "query-string";
import { fetchAPI, MainRowsResponse } from "helpers/apiHelpers";
import { ColumnProductPublishPriceConfig } from "components/Columns/ColumnProductPublishPriceConfig";
import moment from "moment";
import { SyncOutlined, LoadingOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;

type Props = {
    flag: 'ADD' | 'EDIT' | 'DELETE';
    isModal: boolean;
    setIsModal: Function;
    dataSource: ProductPublishPriceConfigRequestEntity;
    eventPost?: Function;
    eventPut?: Function;
}

type ProtectedType = {
  statusForm: 'Add' | 'Edit';
  data?: ProductPublishPriceConfigRequestEntity;
  refresh?: {
    code?: boolean;
  };
}

const ProtectedInitial:ProtectedType = {
  statusForm: 'Add',
  data: {},
  refresh: {
    code: false
  },
}

const ProductPublishPriceConfigForm = (props:Props) => {
  const [messageApi, contextHolderMsg] = message.useMessage();
  const [notif, contextHolder] = notification.useNotification();
  const [form] = Form.useForm<ProductPublishPriceConfigRequestEntity>();
  const [DataProtected, SetDataProtected] = useState<ProtectedType>(ProtectedInitial);
  const [DataSourcePrice, SetDataSourcePrice] = useState<ProductPublishPriceConfigReponseEntity[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { Title } = Typography

  const handleFormReset = useCallback(() => {
    SetDataProtected({
      statusForm: 'Add'
    })
    form.resetFields();
    form.setFieldsValue(props.dataSource)
  }, [form, props.dataSource]);

  const ApiGetPriceConfig = useCallback(async () => {
    try {
      const params:any = {
        paymentPublishId: props.dataSource.payment_publish_id,
        sortBy: "created_at.desc"
      }

      messageApi.loading({
        content: 'Action in progress..',
        duration: 0
      })

      const response: MainRowsResponse<ProductPublishPriceConfigReponseEntity> | null = await fetchAPI(
        `olo/product-publish/prices?${queryString.stringify(params)}`
      );

      const datas = response?.data?.rows?.map((data:ProductPublishPriceConfigReponseEntity) => {
        return {
          ...data,
          status_label: data.status === 1 ? 'Active' : 'Draft',
          start_period_label: data.start_period ? moment(data.start_period).zone(data.start_period).format("DD MMM YYYY HH:mm") : '',
          end_period_label: data.end_period ? moment(data.end_period).zone(data.end_period).format("DD MMM YYYY HH:mm") : ''
        };
      }) ?? [];

      SetDataSourcePrice(datas)

      messageApi.destroy()
    } catch (error: any) {
    messageApi.destroy()
    }
  }, [messageApi, props.dataSource.payment_publish_id]);

  const APIRequestPriceConfig = useCallback(async (val:ProductPublishPriceConfigRequestEntity) => {
    const data = {  
      code: val.code,
      partnerCode: val.partner_code,
      channelCode: val.channel_code,
      paymentPublishId: Number(val.payment_publish_id),
      basePrice: Number(val.base_price),
      vat: Number(val.vat),
      mdr: Number(val.mdr),
      pricePaymentMethod: Number(val.price_payment_method),
      finalPrice: Number(val.final_price),
      platformFee: Number(val.platform_fee),
      startPeriod: val.start_period,
      endPeriod: val.end_period,
      type: val.type,
      status: val.status ? 1 : 0
    }

    messageApi.loading({
        content: 'Action in progress..',
        duration: 0
    })
    
    const Method = DataProtected.statusForm === 'Add' ? 'POST' : 'PUT';
    const Id = val.id ?? ''

    await fetchAPI(`olo/product-publish/prices/${Id}`, Method, data).then((res:any) => {
        if(res.code === 200 ) {
            notif.success({
                message: 'Success',
                description: res.data
            })
            messageApi.destroy()
            handleFormReset()
            ApiGetPriceConfig()

        }
    }).catch((error: any) => {
        notif.error({
            message: 'Error',
            description: `${error.message}`
        })
        messageApi.destroy()
    });
  }, [ApiGetPriceConfig, DataProtected.statusForm, handleFormReset, messageApi, notif])

  const APIDeletePriceConfig = useCallback(async (val:ProductPublishPriceConfigReponseEntity) => {
    messageApi.loading({
        content: 'Action in progress..',
        duration: 0
    })
    
    const Id = val.id ?? ''

    await fetchAPI(`olo/product-publish/prices/${Id}`, 'delete').then((res:any) => {
        if(res.code === 200 ) {
            notif.success({
                message: 'Success',
                description: "Data Successfully Deleted"
            })
            messageApi.destroy()
            handleFormReset()
            ApiGetPriceConfig()

        }
    }).catch((error: any) => {
        notif.error({
            message: 'Error',
            description: `${error.message}`
        })
        messageApi.destroy()
    });
  }, [ApiGetPriceConfig, handleFormReset, messageApi, notif])

  const ApiGeneratedCode = useCallback(async () => {
    try {
      messageApi.loading({
        content: 'Action in progress..',
        duration: 0
      })

      SetDataProtected({
        ...DataProtected,
        refresh: {
          code: true
        }
      })

      const data = {
        publishID: props.dataSource.publish_id_label,
        paymentMethod: props.dataSource.payment_publish_code_label
      }

      await fetchAPI(`olo/generateCode/product-publish/prices`, 'POST', data).then((res:any) => {
       form.setFieldValue('code', res.data)

        setTimeout(() => {
          SetDataProtected({
            ...DataProtected,
            data: {
              ...DataProtected.data,
              code: res.data
            },
            refresh: {
              code: false
            }
          }) 
        }, 500)
       
      }).catch((error: any) => {
          notif.error({
              message: 'Error',
              description: `${error.message}`
          })
          messageApi.destroy()
      });
      messageApi.destroy()

    } catch (error: any) {
      notif.error({
        message: 'Error',
        description: `${error?.message}`
      })
    }
  }, [DataProtected, form, messageApi, notif, props.dataSource.payment_publish_code_label, props.dataSource.publish_id_label]);

  const onFinish = (values:any) => {
      values.start_period = dayjs(values.range_date?.[0]).format('YYYY-MM-DD')
      values.end_period = dayjs(values.range_date?.[1]).format('YYYY-MM-DD')
      APIRequestPriceConfig(values)
  };
  
  const onFinishFailed: FormProps<ProductPublishPaymentRequestEntity>['onFinishFailed'] = (errorInfo) => {
      console.log('Failed:', errorInfo);
  };
  
  const handleMapDataSource = useCallback(() => {
    handleFormReset()
}, [handleFormReset])

  useEffect(() => {
    setIsModalOpen(props.isModal)
    handleMapDataSource()
    ApiGetPriceConfig()
  }, [props.isModal, ApiGetPriceConfig, handleMapDataSource])

  const handleEdit = (val:ProductPublishPriceConfigReponseEntity) => {
    SetDataProtected({
      statusForm: 'Edit'
    })
    form.setFieldsValue(val as ProductPublishPriceConfigRequestEntity)
    form.setFieldValue('range_date', [
      dayjs(val.start_period, 'YYYY-MM-DD'), dayjs(val.end_period, 'YYYY-MM-DD')
    ])
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
    props.setIsModal(false);
    form.resetFields();
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day').subtract(1, 'day');
  };

  return (
    <Fragment>
      {contextHolderMsg}
      {contextHolder}
      <Modal open={isModalOpen} onCancel={handleModalClose} footer={null} width={1000} maskClosable={false}>
        <Row>
            <Col span={24}>
                <Title
                    className="text-blue-d"
                    style={{ fontSize: "18px", textAlign: 'center' }}
                >
                    {`${DataProtected.statusForm} Price Config`}
                </Title>
            </Col>
        </Row>
        <Row justify="center" align="middle" style={{ marginTop: 20 }} className="cs-modal-data">
            <Col span={24}>
                <Form 
                    name="basic"
                    form={form} 
                    layout="vertical" 
                    autoComplete="off" 
                    labelCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item<ProductPublishPriceConfigRequestEntity> name="id" label="id" hidden>
                      <Input/>
                    </Form.Item>

                    <Form.Item<ProductPublishPriceConfigRequestEntity> name="payment_publish_id" label="payment_publish_id" hidden>
                      <Input />
                    </Form.Item>

                    <Form.Item<ProductPublishPriceConfigRequestEntity> name="partner_code" label="partner_code" hidden>
                      <Input />
                    </Form.Item>

                    <Form.Item<ProductPublishPriceConfigRequestEntity> name="channel_code" label="channel_code" hidden>
                      <Input />
                    </Form.Item>

                    <Row>
                      <Col span={12} className="px-2">
                        <Form.Item<ProductPublishPriceConfigRequestEntity> name="range_date" label="Date Periode" rules={[{ required: true }]} >
                          <RangePicker 
                            className="w-full"
                            disabledDate={disabledDate}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item<ProductPublishPriceConfigRequestEntity> name="code" label="Price Code" rules={[{ required: true }]}>
                          <Space.Compact style={{ width: '100%' }}>
                            <Input disabled value={form.getFieldValue('code')}/>
                            <Button disabled={DataProtected.statusForm === 'Edit' ? true : false} onClick={ApiGeneratedCode} > {DataProtected.refresh?.code ? <LoadingOutlined /> : <SyncOutlined />}</Button>
                          </Space.Compact>
                        </Form.Item>
                      </Col>

                      <Col span={6} className="px-2">
                        <Form.Item<ProductPublishPriceConfigRequestEntity> name="type" label="Type" rules={[{ required: true }]}>
                          <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                          >
                            <Option value="regular">Regular</Option>
                            <Option value="promo">Promo</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={6} className="px-2">
                        <Form.Item<ProductPublishPriceConfigRequestEntity> name="base_price" label="Base Price" rules={[{ required: true }]}>
                          <Input type="number" min={0}  />
                        </Form.Item>
                      </Col>

                      <Col span={6} className="px-2">
                        <Form.Item<ProductPublishPriceConfigRequestEntity> name="mdr" label="MDR" rules={[{ required: true }]}>
                          <Input type="number" min={0}  />
                        </Form.Item>
                      </Col>

                      <Col span={6} className="px-2">
                        <Form.Item<ProductPublishPriceConfigRequestEntity> name="vat" label="VAT" rules={[{ required: true }]}>
                          <Input type="number" min={0}  />
                        </Form.Item>
                      </Col>

                      <Col span={6} className="px-2">
                        <Form.Item<ProductPublishPriceConfigRequestEntity> name="price_payment_method" label="Price with Payment Method" rules={[{ required: true }]}>
                          <Input type="number" min={0}  />
                        </Form.Item>
                      </Col>

                      <Col span={6} className="px-2">
                        <Form.Item<ProductPublishPriceConfigRequestEntity> name="final_price" label="Final Price" rules={[{ required: true }]}>
                          <Input type="number" min={0}  />
                        </Form.Item>
                      </Col>

                      <Col span={6} className="px-2">
                        <Form.Item<ProductPublishPriceConfigRequestEntity> name="platform_fee" label="Platform Fee" rules={[{ required: true }]}>
                          <Input type="number" min={0} />
                        </Form.Item>
                      </Col>

                      <Col span={6} className="px-2">
                        <Form.Item<ProductPublishPriceConfigRequestEntity> name="status" label="Status" valuePropName="checked">
                          <Switch checkedChildren="Active" unCheckedChildren="Draft" className="border border-gray-200 bg-gray-500" disabled={DataProtected.statusForm === 'Add' ? true: false}></Switch>
                        </Form.Item>
                      </Col>

                    </Row>
                    <Form.Item>
                        <Flex wrap justify="flex-end" gap="middle" style={{marginTop: '20px'}}>
                            <Button onClick={handleFormReset} style={{border: '1px solid #2B3674', color: '#2B3674'}}>Reset</Button>
                            <Button htmlType="submit" style={{border: '1px solid #2B3674', backgroundColor: '#2B3674', color: 'white'}}>{`${DataProtected.statusForm}`} Submit</Button>
                        </Flex>
                    </Form.Item>
                </Form>
            </Col>
        </Row>

        <Row className="mb-2">
          <Col span={8}>
            <Title level={5}>Payment: {props.dataSource.payment_publish_code_label}</Title>
          </Col>
          <Col span={8}>
            <Title level={5}>Channel: {props.dataSource.channel_code}</Title>
          </Col>
        </Row>
        
        <Table 
        scroll={{x: 200}}
          dataSource={DataSourcePrice} 
          columns={ColumnProductPublishPriceConfig({
            actionSlot: (_: unknown, record: ProductPublishPriceConfigReponseEntity) => (
              <Space size="middle">
                <Button
                  type="text"
                  className="border border-orange-500 text-orange-500"
                  disabled={record.status === 1 ? true : false}
                  onClick={() => handleEdit(record)}
                >
                      Edit
                  </Button>
                <Button
                    type="text"
                    onClick={() => APIDeletePriceConfig(record)}
                    disabled={record.status === 1 ? true : false}
                    className="border border-pink-600 text-pink-600"
                >
                    Delete
                </Button>
              </Space>
          ),
          })}
        />
      </Modal>
    </Fragment>
  )
}

export default ProductPublishPriceConfigForm;
