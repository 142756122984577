import { Button, Checkbox, Col, Flex, Form, FormProps, Input, Modal, Row, Select, Typography } from "antd"
import { ProductPublishPaymentRequestEntity } from "Entity/ProductPublishPaymentEntity";
import { fetchAPI, MainRowsResponse } from "helpers/apiHelpers";
import { failedGetDataMessage } from "helpers/defaultMessage";
import queryString from "query-string";
import { Fragment, useCallback, useEffect, useState } from "react";
import { PaymentMethodReponseEntity } from "Entity/PaymentMethodEntity";

const Option = Select

type Props = {
    flag: 'ADD' | 'EDIT' | 'DELETE';
    isTitle: string;
    isModal: boolean;
    setIsModal: Function;
    dataSource: any;
    eventPost?: Function;
    eventPut?: Function;
}

const ProductPublishPaymentForm = (props:Props) => {
  const [form] = Form.useForm<ProductPublishPaymentRequestEntity>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [DataSourcePayment, SetDataSourcePayment] = useState<PaymentMethodReponseEntity[]>([]);
  const { Title } = Typography

  const ApiGetPayment = useCallback(async () => {
    try {
      const params:any = {
        active: true,
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<PaymentMethodReponseEntity> | null = await fetchAPI(
        `olo/paymentMethods?${queryString.stringify(params)}`
      );
      SetDataSourcePayment(response?.data?.rows ?? [])
    } catch (error: any) {
      alert(error?.message ?? failedGetDataMessage);
    }
  }, []);

  useEffect(() => {
    ApiGetPayment()
  }, [ApiGetPayment])

  const onFinish = (values:any) => {
      if(props.flag === 'ADD') {
          props.eventPost?.(values)
      } 
      if(props.flag === 'EDIT') {
          props.eventPut?.(values)
      } 
  };
  
  const onFinishFailed: FormProps<ProductPublishPaymentRequestEntity>['onFinishFailed'] = (errorInfo) => {
      console.log('Failed:', errorInfo);
  };
  
  const handleMapDataSource = useCallback(() => {
      if(props.flag === 'EDIT') {
          form.setFieldsValue(props.dataSource)
      }
      if(props.flag === 'ADD') {
          form.resetFields();
          form.setFieldsValue(props.dataSource)
      }
  }, [props.flag, props.dataSource, form])

  useEffect(() => {
    setIsModalOpen(props.isModal)
    handleMapDataSource()
  }, [props.isModal, handleMapDataSource])

  const closeForm = () => {
    setIsModalOpen(false);
    props.setIsModal(false);
    form.resetFields();
  };

  return (
    <Fragment>
      {/* {contextHolderMsg} */}
      <Modal open={isModalOpen} onCancel={closeForm} footer={null} width={700} maskClosable={false}>
        <Row>
            <Col span={24}>
                <Title
                    className="text-blue-d"
                    style={{ fontSize: "18px", textAlign: 'center' }}
                >
                    {props.isTitle}
                </Title>
            </Col>
        </Row>
        <Row justify="center" align="middle" style={{ marginTop: 20 }} className="cs-modal-data">
            <Col span={24}>
                <Form 
                    name="basic"
                    form={form} 
                    layout="vertical" 
                    autoComplete="off" 
                    labelCol={{ span: 12 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item<ProductPublishPaymentRequestEntity> name="id" label="id" hidden>
                      <Input disabled={props.flag === 'EDIT' ? true : false} />
                    </Form.Item>

                    <Row>
                      <Col span={24} className="px-2">
                        <Form.Item<ProductPublishPaymentRequestEntity> name="id" label="ID" hidden>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={24} className="px-2">
                        <Form.Item<ProductPublishPaymentRequestEntity> name="product_publish_id" label="product_publish_id" hidden>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={24} className="px-2">
                        <Form.Item<ProductPublishPaymentRequestEntity> name="payment_method_code" label="Payment Method" rules={[{ required: true }]} >
                          <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                            mode={props.flag === 'EDIT' ? undefined : "multiple"}
                          >
                            {DataSourcePayment?.map((item, i) => (
                              <>
                                <Option value={`${item.code}`}>{item.name}</Option>
                              </>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24} className="px-2">
                        <Form.Item<ProductPublishPaymentRequestEntity> name="active" label="Status" valuePropName="checked">
                          <Checkbox></Checkbox>
                        </Form.Item>
                      </Col>

                    </Row>
                    <Form.Item>
                        <Flex wrap justify="flex-end" gap="middle" style={{marginTop: '20px'}}>
                            <Button onClick={closeForm} style={{border: '1px solid #2B3674', color: '#2B3674'}}>Close</Button>
                            <Button htmlType="submit" style={{border: '1px solid #2B3674', backgroundColor: '#2B3674', color: 'white'}}>Submit</Button>
                        </Flex>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
      </Modal>
    </Fragment>
  )
}

export default ProductPublishPaymentForm;
