import { Button, Checkbox, Col, Flex, Form, FormProps, Input, message, Modal, notification, Row, Select, Space, Typography } from "antd"
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import { OloProductReponseEntity, OloProductRequestEntity } from "Entity/OloProductEntity";
import { Fragment, useCallback, useEffect, useState } from "react";
import { fetchAPI, MainRowsResponse } from "helpers/apiHelpers";
import queryString from "query-string";
import { failedGetDataMessage } from "helpers/defaultMessage";
import { GroupBusinessData } from "pages/GroupBusiness";
import usePrivilegedUsers from "customhook/usePrivilegedUsers";
import { BusinessCategoryData } from "pages/BusinessCategory";
import { SyncOutlined, LoadingOutlined } from '@ant-design/icons';

const Option = Select

type Props = {
    flag: 'ADD' | 'EDIT' | 'DELETE';
    isTitle: string;
    isModal: boolean;
    setIsModal: Function;
    dataSource: OloProductReponseEntity;
    eventPost?: Function;
    eventPut?: Function;
}

type ProtectedType = {
  data?: OloProductReponseEntity;
  refresh?: {
    code?: boolean;
  };
  hidden?: {
    game?: boolean;
    base_price?: boolean;
    gimmick_price?: boolean;
  }
}
const ProtectedInitial:ProtectedType = {
  data: {},
  refresh: {
    code: false
  },
  hidden: {
    game: false,
    base_price: false,
    gimmick_price: false,
  }
}

const ProductForm = (props:Props) => {
  const [form] = Form.useForm<OloProductReponseEntity>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [notif, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMsg] = message.useMessage();
  const [DataSource, SetDataSource] = useState<OloProductReponseEntity[]>([]);
  const [DataSourceGroup, SetDataSourceGroup] = useState<GroupBusinessData[]>([]);
  const [DataSourceCategory, SetDataSourceCategory] = useState<BusinessCategoryData[]>([]);
  const [DataProtected, SetDataProtected] = useState<ProtectedType>(ProtectedInitial);
  const PrivilegedUsers = usePrivilegedUsers();
  const { Title } = Typography

  const ApiGetGame = useCallback(async () => {
    try {
      messageApi.loading({
        content: 'Action in progress..',
        duration: 0
      })

      const params:any = {
        type: 'game',
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<OloProductReponseEntity> | null = await fetchAPI(
        `olo/products?${queryString.stringify(params)}`
      );
      SetDataSource(response?.data?.rows ?? [])
      messageApi.destroy()
    } catch (error: any) {
      SetDataSource([])
      notif.error({
        message: 'Error',
        description: `${error?.message ?? failedGetDataMessage}`
      })
    }
  }, [notif, messageApi]);

  const ApiGeneratedCode = useCallback(async () => {

    if(!form.getFieldValue('type') || !form.getFieldValue('category_code')) {
      return false
    }

    try {
      messageApi.loading({
        content: 'Action in progress..',
        duration: 0
      })

      SetDataProtected({
        ...DataProtected,
        refresh: {
          code: true
        }
      })

      const data = {
        type: form.getFieldValue('type'),
        categoryCode: form.getFieldValue('category_code')
      }

      await fetchAPI(`olo/generateCode/products`, 'POST', data).then((res:any) => {
       form.setFieldValue('code', res.data)

        setTimeout(() => {
          SetDataProtected({
            ...DataProtected,
            data: {
              ...DataProtected.data,
              code: res.data
            },
            refresh: {
              code: false
            }
          }) 
        }, 500)
       
      }).catch((err: any) => {
          notif.error({
              message: 'Error',
              description: `${JSON.stringify(err)}`
          })
          messageApi.destroy()
      });
      messageApi.destroy()

    } catch (error: any) {
      SetDataSource([])
      notif.error({
        message: 'Error',
        description: `${error?.message ?? failedGetDataMessage}`
      })
    }
  }, [DataProtected, form, messageApi, notif]);

  const ApiGetGroupBussines = useCallback(async () => {
    if (Object.keys(PrivilegedUsers?.groups).length) {
      try {
        const qs = `?inCode=` + PrivilegedUsers?.groups.toString();
        const response: MainRowsResponse<GroupBusinessData> | null = await fetchAPI(`business/groups${qs}`);
        SetDataSourceGroup(response?.data?.rows ?? [])
      } catch (error: any) {
        alert(error?.message ?? failedGetDataMessage);
      }
    }

  }, [PrivilegedUsers?.groups]);

  const ApiGetCategoryByGroup = async (query?: {group_code?:string;}) => {
    try {
      messageApi.loading({
        content: 'Action in progress..',
        duration: 0
      })

      const params:any = {
        ...query,
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<BusinessCategoryData> | null = await fetchAPI(`business/categories?${queryString.stringify(params)}`);
      SetDataSourceCategory(response?.data?.rows ?? [])
      messageApi.destroy()
    } catch (error: any) {
      SetDataSourceCategory([])
      notif.error({
        message: 'Error',
        description: `${error?.message ?? failedGetDataMessage}`
      })
    }
  };

  const handleGetListCategory = (value:string) => {
    form.setFieldValue("category_code", "")
    if(value) {
      ApiGetCategoryByGroup({group_code: value});
    } else {
      SetDataSourceCategory([])
    }
  }

  const handleChangeType = useCallback( async (value:OloProductReponseEntity) => {
    if(value.type === 'game') {
      form.setFieldValue('parent', '')  
    }
    SetDataProtected({
      ...DataProtected,
      data: {
        ...value,
        ...DataProtected.data,
        type: value.type,
      },
      hidden: {
        game: (value.type === 'game') ? true : false,
        base_price: (value.type === 'game') ? true : false,
        gimmick_price: (value.type === 'game') ? true : false
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ApiGetGroupBussines()
    ApiGetGame()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = (values:any) => {
      if(props.flag === 'ADD') {
          props.eventPost?.(values)
      } 
      if(props.flag === 'EDIT') {
          props.eventPut?.(values)
      } 
  };
  
  const onFinishFailed: FormProps['onFinishFailed'] = (errorInfo) => {
      console.log('Failed:', errorInfo);
  };
  
  const handleMapDataSource = useCallback(() => {
      form?.resetFields();
      if(props?.flag === 'ADD') {
        SetDataProtected(ProtectedInitial)
        handleChangeType(props?.dataSource)
      }

      if(props?.flag === 'EDIT') {
        form.setFieldsValue(props?.dataSource)
        handleChangeType(props?.dataSource)
      }
  }, [form, props?.flag, props?.dataSource, handleChangeType])
  
  useEffect(() => {
    setIsModalOpen(props.isModal)
    handleMapDataSource()
  }, [props.isModal, handleMapDataSource])

  const closeForm = () => {
    setIsModalOpen(false);
    props.setIsModal(false);
    form.resetFields();
  };

  return (
    <Fragment>
      {contextHolder}
      {contextHolderMsg}
      <Modal open={isModalOpen} onCancel={closeForm} footer={null} width={700} maskClosable={false}>
        <Row>
            <Col span={24}>
                <Title
                    className="text-blue-d"
                    style={{ fontSize: "18px", textAlign: 'center' }}
                >
                    {props.isTitle}
                </Title>
            </Col>
        </Row>
        <Row justify="center" align="middle" style={{ marginTop: 20 }} className="cs-modal-data">
            <Col span={24}>
                <Form 
                    name="basic"
                    form={form} 
                    layout="vertical" 
                    autoComplete="off" 
                    labelCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Row>
                      <Col span={24} className="px-2">
                        <Form.Item<OloProductRequestEntity> name="type" label="Please choose type game or variant" rules={[{ required: true }]} >
                          <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                            onChange={async (val) => {
                              await ApiGeneratedCode()
                              const data = {
                                ...DataProtected,
                                data: {
                                  ...DataProtected.data,
                                  type: val,
                                }
                              }
                              handleChangeType(data.data);
                              
                            }}
                          >
                            <Option value="game">Main</Option>
                            <Option value="item">Variant</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2">
                        <Form.Item<OloProductRequestEntity> name="group_code" label="Group" rules={[{ required: true }]} >
                          <Select 
                            allowClear
                            onChange={handleGetListCategory}
                          >
                            {DataSourceGroup?.map((item, i) => (
                              <>
                                <Option key={i} value={`${item.group_code}`}>{`${item.group_code} | ${item.group_name}`}</Option>
                              </>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2">
                        <Form.Item<OloProductRequestEntity> name="category_code" label="Category Code" rules={[{ required: true }]} >
                          <Select
                            allowClear
                            onChange={ApiGeneratedCode}
                          >
                            {DataSourceCategory?.map((item, i) => (
                              <>
                                <Option key={i} value={`${item.category_code}`}>{`${item.category_code} | ${item.category_name}`}</Option>
                              </>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2">
                        <Form.Item<OloProductRequestEntity> name="code" label="Code" rules={[{ required: true }]} >
                          <Space.Compact style={{ width: '100%' }}>
                            <Input disabled value={DataProtected.data?.code}/>
                            <Button disabled={props.flag === 'EDIT' ? true : false} onClick={ApiGeneratedCode} > {DataProtected.refresh?.code ? <LoadingOutlined /> : <SyncOutlined />}</Button>
                          </Space.Compact>
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2">
                        <Form.Item<OloProductRequestEntity> name="name" label="Name" rules={[{ required: true }]} >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={24} className="px-2">
                        <Form.Item<OloProductRequestEntity> name="title" label="Title" rules={[{ required: true }]} >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2" hidden={DataProtected.hidden?.game}>
                        <Form.Item<OloProductRequestEntity> name="parent" label="Game" rules={[{ required: !DataProtected.hidden?.game }]}>
                          <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                          >
                            {DataSource?.map((item, i) => (
                              <>
                                <Option key={i} value={`${item.code}`}>{`${item.code} | ${item.name}`}</Option>
                              </>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2" hidden={DataProtected.hidden?.base_price}>
                        <Form.Item<OloProductRequestEntity> name="base_price" label="Base Price" >
                          <Input type="number" min={0} />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2" hidden={DataProtected.hidden?.gimmick_price}>
                        <Form.Item<OloProductRequestEntity> name="gimmick_price" label="Gimmick Price" >
                          <Input type="number" min={0} />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2">
                        <Form.Item<OloProductRequestEntity> name="image_external_url" label="Image External URL" rules={[{ required: true }]} >
                          <Input />
                        </Form.Item>
                      </Col>
                      
                      <Col span={12} className="px-2">
                        <Form.Item<OloProductRequestEntity> name="active" label="Active" valuePropName="checked">
                          <Checkbox></Checkbox>
                        </Form.Item>
                      </Col>

                      {/* <Col span={12} className="px-2">
                        <Form.Item<OloProductRequestEntity> name="image" label="Image" className="mb-0">
                            <Upload.Dragger maxCount={1} beforeUpload={() => false} accept="image/*" >
                              <p className="ant-upload-hint">Upload Here</p>
                            </Upload.Dragger>
                        </Form.Item>
                        {(props.flag === 'EDIT' && form.getFieldValue('image')) && (
                              <Flex justify="center">
                                <img src={form.getFieldValue('image')} alt={form.getFieldValue('image')} className="max-w-[300px]" />
                              </Flex>
                          )}
                      </Col>                       */}
                    </Row>

                    <Form.Item<OloProductRequestEntity> name="description" label="Description" rules={[{ required: true }]} className="mt-2 px-2">
                      <TextArea rows={4} />
                    </Form.Item>

                    <Title level={5}>Metadata:</Title>
                    <Form.List name="metadata">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Row 
                              key={key} 
                            >
                              <Col span={11}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'id']}
                                  hidden
                                >
                                  <Input placeholder="key"/>
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'key']}
                                  rules={[{ required: true, message: 'Missing key' }]}
                                >
                                  <Input placeholder="key"/>
                                </Form.Item>
                              </Col>
                              <Col span={12} className="px-4">
                                <Form.Item
                                  {...restField}
                                  name={[name, 'value']}
                                  rules={[{ required: true, message: 'Missing value' }]}
                                >
                                  <Input placeholder="value" />
                                </Form.Item>
                              </Col>
                              <Col span={1}>
                                <MinusCircleOutlined onClick={() => remove(name)} className="pt-2"/>
                              </Col>
                            </Row>
                          ))}
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              Add field
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>

                    <Form.Item>
                        <Flex wrap justify="flex-end" gap="middle" style={{marginTop: '20px'}}>
                            <Button onClick={closeForm} style={{border: '1px solid #2B3674', color: '#2B3674'}}>Close</Button>
                            <Button htmlType="submit" style={{border: '1px solid #2B3674', backgroundColor: '#2B3674', color: 'white'}}>Submit</Button>
                        </Flex>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
      </Modal>
    </Fragment>
  )
}

export default ProductForm;
