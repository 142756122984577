import { Button, Col, Form, Input, Row, Select, Space, Table, message, notification } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnProductPublish } from "components/Columns/ColumnProductPublish";
import useCurrentMenu from "customhook/useCurrentMenu";
import { ProductPublishReponseEntity } from "Entity/ProductPublishEntity";
import { fetchAPI, MainRowsResponse } from "helpers/apiHelpers";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import queryString from "query-string";
import BreadcrumbComponent from "components/BreadcrumbComponent";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Link } from "react-router-dom";

const { Option } = Select;

const DonwloadHeadersCSV = [
  { label: "ID", key: "id" },
  { label: "Partner Code", key: "partner_code" },
  { label: "Channel Code", key: "channel_code" },
  { label: "Product Code", key: "product_code" },
  { label: "Product SKU ID", key: "product_sku_id" },
  { label: "Start Date", key: "start_date" },
  { label: "Start Periode", key: "start_date" },
  { label: "End Periode", key: "end_date" },
  { label: "Status", key: "status_label" },
];

const ProductPublish = () => {
  const currentMenu = useCurrentMenu();
  const [form] = Form.useForm();
  const [notif, contextHolder] = notification.useNotification();
  const [messageApi, contextHolderMsg] = message.useMessage();
  const [DataSource, SetDataSource] = useState<ProductPublishReponseEntity[]>([]);

  const fetchDatas = useCallback(async (query?: {code?:string; name?: string}) => {
    try {

      messageApi.loading({
        content: 'Action in progress..',
        duration: 0
      })

      const params:any = {
        ...query,
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<ProductPublishReponseEntity> | null = await fetchAPI(
        `olo/productPublish?${queryString.stringify(params)}`
      );

      const datas = response?.data?.rows?.map((data:ProductPublishReponseEntity) => {
          return {
            ...data,
            status: (data.status === 1) ? true : false,
            status_label: (data.status === 1) ? "Active" : "Draft",
            start_date: data.start_date ? moment(data.start_date).zone(data.start_date).format("DD MMM YYYY") : '',
            end_date: data.end_date ? moment(data.end_date).zone(data.end_date).format("DD MMM YYYY") : '',
            created_at: data.created_at ? moment(data.created_at).zone(data.created_at).format("DD MMM YYYY HH:mm") : '',
            updated_at: data.updated_at ? moment(data.updated_at).zone(data.updated_at).format("DD MMM YYYY HH:mm") : ''
          };
        }) ?? [];
      
        SetDataSource(datas)
        messageApi.destroy()
    } catch (error: any) {
      messageApi.destroy()
      notif.error({
        message: 'Error',
        description: `${error.message}`
    })
    }
  }, [messageApi, notif]);

  const ApiDeleteContent = async (item:ProductPublishReponseEntity) => {
    if(window.confirm('Are you sure?')) {

        messageApi.loading({
            content: 'Action in progress..',
            duration: 0
        })

        await fetchAPI(`olo/productPublish/${item.id}`, 'DELETE').then((res:any) => {
            if(res.code === 200 ) {
                notif.success({
                    message: 'Success',
                    description: res.data
                })
                messageApi.destroy()
              fetchDatas()
            }
        }).catch((err: any) => {
            notif.error({
                message: 'Error',
                description: err.response.data.message
            })
            messageApi.destroy()
        });
    }
  }

  const handleFilter =  (val?: {searchBy?: any; searchData?: string}) => {
    fetchDatas({
      [val?.searchBy]: [val?.searchData],
    });
  }
  
  const handleFilterClear = () => {
    form.resetFields();
    fetchDatas()
  }

  const handleDownloadData = async () => {
    messageApi.loading({
      content: 'Download in progress..',
      duration: 0
    })

    setTimeout(() => {
      csvLinkRef?.current?.link.click();
      messageApi.destroy()
    }, 1500);
  }

  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  useEffect(() => {
    fetchDatas()
  }, [fetchDatas])

  if (!currentMenu.is_view) {
    return <div>Access denied</div>;
  }

  return (
    <Fragment>
      {contextHolder}
      {contextHolderMsg}
      <CSVLink
        headers={DonwloadHeadersCSV}
        data={DataSource}
        filename={"export-product-publish.csv"}
        ref={csvLinkRef}
      />
      <BreadcrumbComponent active={"Product Publish"} />
      <Content>
        <Form
          form={form}
          layout={"vertical"}
          onFinish={handleFilter}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="Search By"
                name="searchBy"
                rules={[{ required: true, message: 'Search By is required'}]}
              >
                <Select
                  placeholder="Select a option and change input text above"
                  allowClear
                >
                  <Option value="productCode">Product Code</Option>
                  <Option value="partnerCode">Partner Code</Option>
                  <Option value="channelCode">Channel</Option>
                  <Option value="productSku">Product SKU</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Search Data"
                name="searchData"
                rules={[{ required: true, message: 'Search Data is required'}]}
              >
                <Input placeholder="Search Data" />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="bg-pink-600 hover:bg-pink-700 text-white font-bold">
                  Submit Filter
                </Button>
                <Button type="text" htmlType="button" className="border border-gray-500 text-gray-500 font-bold ml-3" onClick={handleFilterClear}>
                  Clear
                </Button>
              </Form.Item>
            </Col>

            <Col span={6}>
              {currentMenu.is_create && (
                <Link to={'/cms/olo/product-publish/form'}>
                  <Button className="bg-pink-600 hover:bg-pink-700 text-white font-bold mb-3 float-right">
                      Add New
                  </Button>
                </Link>
              )}
              {currentMenu.is_download && (
                <Button
                  onClick={handleDownloadData}
                  className="bg-gray-600 hover:bg-gray-700 text-white font-bold mb-3 mr-3 float-right" 
                >
                    Download
                </Button>
              )}
            </Col>
          </Row>
        </Form>

        <Table 
          dataSource={DataSource} 
          columns={ColumnProductPublish({
            actionSlot: (_: unknown, record: ProductPublishReponseEntity) => (
              <Space size="middle">
                {currentMenu.is_edit && (
                  <Link to={`/cms/olo/product-publish/form/${record.id}`}>
                    <Button
                      type="text"
                      className="border border-orange-500 text-orange-500"
                    >
                        Edit
                    </Button>
                  </Link>
                )}
                {currentMenu.is_delete && (
                  <Button
                      type="text"
                      onClick={() => ApiDeleteContent(record)}
                      className="border border-pink-600 text-pink-600"
                  >
                      Delete
                  </Button>
                )}
              </Space>
          ),
          })}
        />
      </Content>
    </Fragment>
  )
}

export default ProductPublish;
