export const ColumnProductPublishPriceConfig = ({ actionSlot }: any) => {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Price Code',
            dataIndex: 'code',
            key: 'code',
            className:"whitespace-nowrap"
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            className:"whitespace-nowrap"
        },
        {
            title: 'Base Price',
            dataIndex: 'base_price',
            key: 'base_price',
            className:"whitespace-nowrap"
        },
        {
            title: 'MDR',
            dataIndex: 'mdr',
            key: 'mdr',
            className:"whitespace-nowrap"
        },
        {
            title: 'Price Payment Method',
            dataIndex: 'price_payment_method',
            key: 'price_payment_method',
            className:"whitespace-nowrap"
        },
        {
            title: 'VAT',
            dataIndex: 'vat',
            key: 'vat',
            className:"whitespace-nowrap"
        },
        {
            title: 'Finance Price',
            dataIndex: 'final_price',
            key: 'final_price',
            className:"whitespace-nowrap"
        },
        {
            title: 'Start Date',
            dataIndex: 'start_period_label',
            key: 'start_period_label',
            className:"whitespace-nowrap"
        },
        {
            title: 'End Date',
            dataIndex: 'end_period_label',
            key: 'end_period_label',
            className:"whitespace-nowrap"
        },
        {
            title: 'Status',
            dataIndex: 'status_label',
            key: 'status_label',
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: actionSlot,
        },
      ];
};
  