import { Button, Checkbox, Col, Flex, Form, FormProps, Input, message, Modal, Row, Select, Typography } from "antd"
import usePrivilegedUsers from "customhook/usePrivilegedUsers";
import { OloProductReponseEntity } from "Entity/OloProductEntity";
import { ProductSKURequestEntity } from "Entity/ProductSKUEntity";
import { fetchAPI, MainRowsResponse } from "helpers/apiHelpers";
import { failedGetDataMessage } from "helpers/defaultMessage";
import { PartnerData } from "pages/Partners";
import queryString from "query-string";
import { Fragment, useCallback, useEffect, useState } from "react";
const Option = Select

type Props = {
    flag: 'ADD' | 'EDIT' | 'DELETE';
    isTitle: string;
    isModal: boolean;
    setIsModal: Function;
    dataSource: any;
    eventPost?: Function;
    eventPut?: Function;
}

const ProductSKUForm = (props:Props) => {
  const [messageApi, contextHolderMsg] = message.useMessage();
  const [form] = Form.useForm<ProductSKURequestEntity>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [DataSourceGame, SetDataSourceGame] = useState<OloProductReponseEntity[]>([]);
  const [DataSourceDenom, SetDataSourceDenom] = useState<OloProductReponseEntity[]>([]);
  const [DataSourcePartner, SetDataSourcePartner] = useState<PartnerData[]>([]);
  const PrivilegedUsers = usePrivilegedUsers();
  const { Title } = Typography

  const ApiGetPartner = useCallback(async () => {
    if (Object.keys(PrivilegedUsers?.partners).length) {
      try {
        const params = `?inCode=${PrivilegedUsers.partners.toString()}`
        const response: MainRowsResponse<PartnerData> | null = await fetchAPI(`partners${params}`);

        SetDataSourcePartner(response?.data?.rows ?? [])
      } catch (error: any) {
        alert(error?.message ?? failedGetDataMessage);
      }
    }

  }, [PrivilegedUsers?.partners]);

  const ApiGetGame = useCallback(async () => {
    try {
      const params:any = {
        type: "game",
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<OloProductReponseEntity> | null = await fetchAPI(
        `olo/products?${queryString.stringify(params)}`
      );
      SetDataSourceGame(response?.data?.rows ?? [])
      
    } catch (error: any) {
      SetDataSourceGame([])
    }
  }, []);

  const ApiGetDenom = useCallback(async (query?: {parent?:string;}) => {
    try {

      messageApi.loading({
        content: 'Action in progress..',
        duration: 0
      })

      const params:any = {
        type: "item",
        parent: query?.parent,
        sortBy: "created_at.desc"
      }

      const response: MainRowsResponse<OloProductReponseEntity> | null = await fetchAPI(
        `olo/products?${queryString.stringify(params)}`
      );
      SetDataSourceDenom(response?.data?.rows ?? [])
      messageApi.destroy()
      
    } catch (error: any) {
      SetDataSourceDenom([])
    }
  }, [messageApi]);

  const handleGetListDenom = (value: string) => {
    form.setFieldValue("product_code", "")
    if(value) {
      ApiGetDenom({parent: value});
    } else {
      SetDataSourceDenom([])
    }
  }

  useEffect(() => {
    ApiGetPartner()
    ApiGetGame()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = (values:any) => {
      if(props.flag === 'ADD') {
          props.eventPost?.(values)
      } 
      if(props.flag === 'EDIT') {
          props.eventPut?.(values)
      } 
  };
  
  const onFinishFailed: FormProps<ProductSKURequestEntity>['onFinishFailed'] = (errorInfo) => {
      console.log('Failed:', errorInfo);
  };
  
  const handleMapDataSource = useCallback(() => {
      if(props.flag === 'EDIT') {
          form.setFieldsValue(props.dataSource)
          ApiGetDenom({parent: props.dataSource?.product_code_game});
      }
      if(props.flag === 'ADD') {
          form.resetFields();
      }
  }, [props.flag, props.dataSource, form, ApiGetDenom])

  useEffect(() => {
    setIsModalOpen(props.isModal)
    handleMapDataSource()
  }, [props.isModal, handleMapDataSource])

  const closeForm = () => {
    setIsModalOpen(false);
    props.setIsModal(false);
    form.resetFields();
  };

  return (
    <Fragment>
      {contextHolderMsg}
      <Modal open={isModalOpen} onCancel={closeForm} footer={null} width={700} maskClosable={false}>
        <Row>
            <Col span={24}>
                <Title
                    className="text-blue-d"
                    style={{ fontSize: "18px", textAlign: 'center' }}
                >
                    {props.isTitle}
                </Title>
            </Col>
        </Row>
        <Row justify="center" align="middle" style={{ marginTop: 20 }} className="cs-modal-data">
            <Col span={24}>
                <Form 
                    name="basic"
                    form={form} 
                    layout="vertical" 
                    autoComplete="off" 
                    labelCol={{ span: 12 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item<ProductSKURequestEntity> name="id" label="id" hidden>
                      <Input disabled={props.flag === 'EDIT' ? true : false} />
                    </Form.Item>

                    <Row>
                      <Col span={12} className="px-2">
                        <Form.Item<ProductSKURequestEntity> name="partner_code" label="Partner Code" rules={[{ required: true }]} >
                          <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                          >
                            {DataSourcePartner?.map((item, i) => (
                              <>
                                <Option value={`${item.partner_code}`}>{item.partner_name}</Option>
                              </>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2">
                        <Form.Item<ProductSKURequestEntity> name="product_code_game" label="Game" rules={[{ required: true }]} >
                          <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                            onChange={handleGetListDenom}
                          >
                            {DataSourceGame?.map((item, i) => (
                              <>
                                <Option value={`${item.code}`}>{item.name}</Option>
                              </>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2">
                        <Form.Item<ProductSKURequestEntity> name="product_code" label="Denom" rules={[{ required: true }]} >
                          <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                          >
                            {DataSourceDenom?.map((item, i) => (
                              <>
                                <Option value={`${item.code}`}>{item.name}</Option>
                              </>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2">
                        <Form.Item<ProductSKURequestEntity> name="wholesale_price" label="Wholesale Price" rules={[{ required: true }]} >
                          <Input type="number" min={0} />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2">
                        <Form.Item<ProductSKURequestEntity> name="sku" label="SKU" rules={[{ required: true }]} >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2">
                        <Form.Item<ProductSKURequestEntity> name="status" label="Active" valuePropName="checked">
                          <Checkbox></Checkbox>
                        </Form.Item>
                      </Col>

                    </Row>
                    <Form.Item>
                        <Flex wrap justify="flex-end" gap="middle" style={{marginTop: '20px'}}>
                            <Button onClick={closeForm} style={{border: '1px solid #2B3674', color: '#2B3674'}}>Close</Button>
                            <Button htmlType="submit" style={{border: '1px solid #2B3674', backgroundColor: '#2B3674', color: 'white'}}>Submit</Button>
                        </Flex>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
      </Modal>
    </Fragment>
  )
}

export default ProductSKUForm;
